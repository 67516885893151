import { API_URL } from "../../config"
import store from '../store'

export default {
  namespaced: true,
  actions: {
    async getStock({}, payload){
        const response = await fetch(`${API_URL}/stock`, {
            method: 'POST',
            headers: {
                'Content-type': 'Application/json',
                authorization: store.state.token
            },
            body: JSON.stringify(payload)
        })

        return response.json()
    }
  }
}
