module.exports = {
  // API_URL: 'http://10.0.0.77:5432/api', // DESARROLLO
  // API_JARVIS: 'http://10.0.0.77:3000/api', // DESARROLLO
  API_URL: 'https://api.dycar.com.ar/api', // PRODUCCION
  API_JARVIS: 'https://api.borigenbetzel.com.ar/api', // PRODUCCION
  DEV_URL: 'http://10.0.0.77', // DESARROLLO y PROD
  // PUBLIC_VAPID_KEY:
  // 'BLCAbFHQOan-iW4OsPrO_gT0Pu15VcxBLAxcuZraFkZ4nFTTjaeWDwSUN0JADkPehJwXLRsgiTDllGeR_zw5kkk' // DESARROLLO
  PUBLIC_VAPID_KEY:
    'BA0OJPzboirFZuMYDTaGwak_ng6xzEeRPi9EwM83Q3mTwqIaxcJlHNtaRmgDncw13EEfEhSPhXtuPzi0j0LLYgc' // PRODUCCION
}
  