<template>
    <v-dialog v-model="dialog" max-width="700" scrollable>
        <v-card>
            <v-card-title primary-title>
                <h4>Anular factura {{ factura?.Factura }}</h4>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
                    <v-col cols="12" sm="4" md="4" class="py-1">
                        N° Factura Nota de Crédito
                        <v-text-field-simplemask v-model.trim="data.factura" v-bind:properties="{
                            'hide-details': true,
                            outlined: true,
                            dense: true,
                            placeholder: '####-########',
                            hideDetails: true
                        }" v-bind:options="{
                            inputMask: '####-########',
                            outputMask: '####-########',
                            applyAfter: false,
                            alphanumeric: true,
                            empty: ''
                        }" />
                    </v-col>
                    <v-col cols="12" sm="2" md="12" class="py-0 mt-2 mb-3">
                        <div style="width: 100; display: flex; justify-content: end">
                            <BtnConfirmar @action="anular" icono="fas fa-ban" nombre="Anular" color="error" />
                        </div>
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import TextFieldMoney from '../../../components/util/TextFieldMoney.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
import FechaPicker from '../../util/FechaPicker.vue';

export default {
    data() {
        return {
            load: false,
            data: {
                factura: '',
            },
        }
    },

    methods: {
        async anular() {
            if (this.data.factura.length > 0) {
                this.$store.state.loading = true
                const array = this.data.factura.split('-')
                const punto_de_venta = Number(array[0])
                const numero_desde = Number(array[1])

                if (punto_de_venta && numero_desde) {
                    // Anular la factura
                    await this.$store.dispatch('comprobantesAfip/anularFactura', {
                        comprobante_id: this.factura.Id,
                        punto_de_venta,
                        numero_desde,
                        nro_doc_emisor: this.factura.Nro_doc_emisor,
                        imp_total: this.factura.Imp_total,
                    }).then(res => {
                        if (res.exito) {
                            this.$store.dispatch('show_snackbar', {
                                text: res.message,
                                color: 'success'
                            })
                            this.dialog = false
                        } else {
                            this.$store.dispatch('show_snackbar', {
                                text: res.message,
                                color: 'error'
                            })
                        }
                    })
                } else {
                    this.$store.dispatch('show_snackbar', {
                        text: 'El número de factura ingresado no tiene un formato correcto.',
                        color: 'warning'
                    })
                }
                this.$store.state.loading = false
            } else {
                this.$store.dispatch('show_snackbar', {
                    text: 'El campo es obligatorio.',
                    color: 'warning'
                })
            }
        },
    },

    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },

    props: {
        value: Boolean,
        factura: Object
    },

    watch: {
        async dialog(val) {
            if (val) {
            } else {
                this.data.factura = ''
            }
        }
    },

    components: {
        TextFieldMoney,
        BtnConfirmar,
        FechaPicker
    }
}
</script>