<template>
  <v-dialog v-model="dialog" max-width="800" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Subir archivo</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-5 align-center">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Período
            <PeriodoPicker hide-details v-model="select.periodo" />
          </v-col>
          <v-col cols="12" class="py-1">
            <FileDropzone :files.sync="archivo" :tipos="tipos_validos" :multiple="false" :max_size="25" />
          </v-col>
          <v-col cols="12" class="py-1">
            <div style="display: flex; justify-content: end">
              <BtnConfirmar @action="subir" nombre="subir" icono="fas fa-file-upload" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { read, utils } from "xlsx";
import FileDropzone from "../../util/FileDropzone.vue";
import BtnConfirmar from "../../util/BtnConfirmar.vue";
import PeriodoPicker from "../../util/PeriodoPicker.vue";
import moment from "moment";

export default {
  data() {
    return {
      archivo: [],
      cuit_cuil: "",
      tipos_validos: [".xls", ".xlsx", ".ods"],
      select: {
        periodo: "",
      },
      empresas: [],
      datos: [],
    };
  },

  methods: {
    async subir() {
      if (this.archivo.length > 0) {
        if (this.select.periodo.length > 0) {
          this.$store.state.loading = true;
          this.datos = this.datos.filter((pago) => {
            if (pago.periodo) {
              return pago.periodo == this.select.periodo;
            }
          });
          await this.$store
            .dispatch("mantenimientoPA/subirPagosMasivo", {
              Empresa_id: this.Empresa_id,
              pagos: this.datos,
              periodo: this.select.periodo,
            })
            .then((res) => {
              if (res.exito) {

                this.$swal.fire({
                  icon: "success",
                  title: res.message,
                });

              } else {
                this.$store.dispatch("show_snackbar", {
                  text: res.message,
                  color: "error",
                });
              }
              this.dialog = false;
            });
          this.$store.state.loading = false;
        } else {
          this.$store.dispatch("show_snackbar", {
            text: "Debes seleccionar un período.",
            color: "warning",
          });
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: "Debes seleccionar un archivo.",
          color: "warning",
        });
      }
    },
  },

  watch: {
    async archivo() {
      if (this.archivo.length > 0) {
        const reader = new FileReader();

        let datosModificados = [];
        const columnasEsperadas = [
          "AÑO",
          "CIERRE INCENTIVO",
          "GRUPO / ORDEN",
          "MES AGRUPADO",
        ];

        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = utils.sheet_to_json(worksheet);

          this.datos = jsonData;
          // Verifico la estructura del archivo
          const columnasArchivo = Object.keys(jsonData[0]);
          const columnasFaltantes = columnasEsperadas.filter(
            (columna) => !columnasArchivo.includes(columna)
          );

          if (columnasFaltantes.length > 0) {
            this.$store.dispatch("show_snackbar", {
              text: `Las columnas no estan en el siguiente orden: ${columnasFaltantes.join(
                ", "
              )}`,
              color: "warning",
            });
            return;
          }

          datosModificados = this.datos.map((dato) => {
            let {
              "CIERRE INCENTIVO": cierreIncentivo,
              AÑO: año,
              "MES AGRUPADO": mesAgrupado,
              "CUOTA CON INCENTIVO": cuotaConIncentivo,
              "GRUPO / ORDEN": grupo_orden,
              "Q CUOTAS PAGADAS": cuotasPagadas,
              "IMPORTE PAGADO": importePagado,
              "ACUMULADO DE CUOTAS PAGAS": acumulado,
              OBSERVACION: observacion,
              ...props
            } = dato;

            cierreIncentivo = cierreIncentivo || null;
            año = año || null;
            mesAgrupado = mesAgrupado || null;
            cuotaConIncentivo = cuotaConIncentivo || null;
            grupo_orden = grupo_orden || null;
            cuotasPagadas = cuotasPagadas || null;
            importePagado = parseFloat(importePagado) || null;
            acumulado = acumulado || null;
            observacion = observacion || null;
            let avance = cuotaConIncentivo + 1;

            let grupo = null;
            let orden = null;
            let periodo = "";

            if (grupo_orden) {
              if (grupo_orden.includes("/")) {
                const partes = grupo_orden.split("/");
                grupo = Number(partes[0]);
                orden = Number(partes[1]);
              }
            }

            if (typeof cierreIncentivo === "number") {
              const jsDate = new Date(
                (cierreIncentivo - (25567 + 2)) * 86400 * 1000
              );
              cierreIncentivo = moment.utc(jsDate).format("DD/MM/YYYY");
              periodo = moment.utc(jsDate).format("MM/YYYY");
            }

            return {
              cierreIncentivo,
              año,
              mesAgrupado,
              cuotaConIncentivo,
              grupo_orden,
              cuotasPagadas,
              importePagado,
              acumulado,
              observacion,
              grupo,
              orden,
              avance,
              periodo,
              ...props,
            };
          });

          this.datos = datosModificados;
        };
        reader.readAsArrayBuffer(this.archivo[0]);
      }
    },

    dialog(val) {
      if (!val) {
        this.archivo = [];
        this.select.periodo = "";
      }
    },
  },

  async created() {
    await this.$store.dispatch("comprobantesAfip/getFamilias").then((res) => {
      if (res.exito) {
        this.empresas = res.data;

        this.empresas = this.empresas.filter((empresa) =>
          this.familias.some((familia) => familia.id == empresa.Id)
        );

        this.empresas.forEach((empresa) => {
          empresa.Cuit = empresa.Cuit.replace(/-/g, "");
        });

        if (this.empresas.length == 1) {
          this.filtro.empresa = this.empresas[0].Id;
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: res.message,
          color: "error",
        });
      }
    });
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState(["familias"]),
  },

  props: {
    value: Boolean,
    Empresa_id: Number,
  },

  components: {
    FileDropzone,
    BtnConfirmar,
    PeriodoPicker,
  },
};
</script>
