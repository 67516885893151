<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn v-if="permiso_abm_retira" style="position: absolute; top: -15px; right: 155px; z-index: 1"
              color="success" title="Autorizados a retirar" small dark fab @click="abrirModalABMRetira()">
              <v-icon>fas fa-users</v-icon>
            </v-btn>
            <v-btn style="position: absolute; top: -15px; right: 105px; z-index: 1" color="info" title="ABM Vouchers."
              small dark fab @click="abrirModalABM()">
              <v-icon>fas fa-gas-pump</v-icon>
            </v-btn>
            <v-btn style="position: absolute; top: -15px; right: 55px; z-index: 1" color="success" small fab
              title="Nuevo registro" @click="abrirModalNuevoRecibo()">
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>

            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete v-model="filtro.familia" item-text="nombre" item-value="id" hide-details outlined
                      dense clearable :items="familias" @change="setEmpresas"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Marca
                    <v-autocomplete v-model="filtro.marca" item-text="nombre" item-value="id" hide-details outlined
                      dense clearable :items="empresasFiltradas.filter(
                        emp => emp.familia === filtro.familia
                      )
                        " @change="setSucursal"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Sucursal
                    <v-autocomplete v-model="filtro.sucursal" item-text="provincia" item-value="id" hide-details
                      outlined dense clearable :items="sucursalesFiltradas.filter(
                        suc => suc.familia_id === filtro.familia
                      )
                        "></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Sector
                    <v-autocomplete v-model="filtro.sector" item-text="Nombre" item-value="Id" hide-details outlined
                      dense :items="sectores"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" xl="2" class="py-1">
                    VIN
                    <v-text-field v-model.trim="filtro.vin" hide-details outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" xl="2" class="py-1">
                    Dominio
                    <v-text-field v-model.trim="filtro.patente" hide-details outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" xl="2" class="py-1">
                    Retira
                    <v-text-field v-model.trim="filtro.cliente" hide-details outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" xl="2" class="py-1">
                    Número recibo
                    <v-text-field v-model.trim="filtro.numero" type="number" hide-details outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="pt-7">
                    <FechaPicker v-model="filtro.fecha_desde" label="Fecha desde" hide-details clearable />
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="pt-7">
                    <FechaPicker v-model="filtro.fecha_hasta" label="Fecha hasta" hide-details clearable />
                  </v-col>
                  <v-col cols="12" sm="2" md="12" class="pt-0" style="margin-top: -22px">
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="mt-2">
          <v-data-table class="cebra elevation-0 mt-2" item-key="id" sort-by="id" :headers="headers" :items="recibos"
            :loading="load" sort-desc dense :search="search">
            <template v-for="header in headers.filter(header =>
              header.hasOwnProperty('formatter')
            )" v-slot:[`item.${header.value}`]="{ value }">
              {{ header.formatter(value) }}
            </template>

            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>

                <v-btn @click="descargar" class="mr-2 my-sm-0 my-1 py-sm-0 py-3" title="Descargar Excel"
                  :x-small="$vuetify.breakpoint.xs" :small="!$vuetify.breakpoint.xs">
                  Excel
                  <v-icon color="green darken-3" :small="$vuetify.breakpoint.xs" right>
                    fas fa-file-download
                  </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-col cols="6" sm="3" xl="2">
                  <SearchDataTable v-model="search" />
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.Retira`]="{ item }">
              <span v-if="item.Retira_id">
                {{ autorizados.find(aut => aut.Retira_id == item.Retira_id)?.Nombre }}
              </span>
              <span v-else>
                {{ item.Retira }}
              </span>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn v-if="!(item.Auditado || item.Inhabilitado) && permiso_auditar" small icon color="success"
                @click="abrirModalAuditar(item)" title="Auditar">
                <v-icon class="" small>fas fa-check-double</v-icon>
              </v-btn>
              <v-btn v-if="!(item.Auditado || item.Inhabilitado)" small icon color="warning"
                @click="abrirModalEditar(item)" title="Editar">
                <v-icon class="" small>fas fa-pen</v-icon>
              </v-btn>

              <v-btn v-if="!(item.Auditado || item.Inhabilitado)" small icon color="error"
                @click="abrirModalAnular(item)" title="Anular">
                <v-icon class="" small>fas fa-ban</v-icon>
              </v-btn>

              <v-btn v-if="!(item.Auditado || item.Inhabilitado) && permiso_descargar" small icon color="success"
                @click="descargarReciboPDF(item)" title="Descargar">
                <v-icon class="" small>fas fa-download</v-icon>
              </v-btn>

              <v-btn small icon color="info" @click="abrirModalVerDetalle(item)" title="Ver detalles">
                <v-icon class="" small>fas fa-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <NuevoReciboCombustible @abrirModalVerPdf="handleVerPdf($event)" v-model="modalNuevo" :sectores="sectores" />
    <ABMVouchers v-model="modalABM" />
    <ABMRetira v-model="modalABMRetira" />

    <PDFViewer v-model="modalPDF" :pdf="pdf.data" :nombre="pdf.nombre" :titulo="pdf.titulo" :zoomInicial="pdf.zoom" />

    <AuditarRecibo v-model="modalAuditar" :recibo="reciboSeleccionado" @actualizar="handleActualizar($event)" />
    <EditarRecibo v-model="modalEditar" :recibo="reciboSeleccionado" :sectores="sectores"
      @actualizar="handleActualizar($event)" />
    <AnularRecibo v-model="modalAnular" :recibo="reciboSeleccionado" @actualizar="handleActualizar($event)" />
    <DetallesRecibo v-model="modalVerDetalles" :sectores="sectores" :recibo="reciboSeleccionado"
      @actualizar="handleActualizar($event)" />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'

import { mapState } from 'vuex'
import moment from 'moment'
import ABMVouchers from '../../components/administracion/combustible/ABMVouchers.vue'
import ABMRetira from '../../components/administracion/combustible/ABMRetira.vue'

import NuevoReciboCombustible from '../../components/administracion/combustible/NuevoReciboCombustible.vue'
import PDFViewer from '../../util/plantillas/PDFViewer.vue'
import AuditarRecibo from '../../components/administracion/combustible/AuditarRecibo.vue'
import EditarRecibo from '../../components/administracion/combustible/EditarRecibo.vue'
import AnularRecibo from '../../components/administracion/combustible/AnularRecibo.vue'
import { pdfOrdenDeCompra, pdfVoucherCombustible } from '../../util/plantillas/pdfs'
import DetallesRecibo from '../../components/administracion/combustible/DetallesRecibo.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import FileSaver from 'file-saver'
import { utils, write } from 'xlsx'
import 'moment/locale/es';

export default {
  data() {
    return {
      panel: 0,
      load: false,
      search: '',
      permiso: false,
      permiso_auditar: false,
      permiso_descargar: false,
      permiso_abm_retira: false,

      empresasFiltradas: [],
      sucursalesFiltradas: [],

      sectores: [],

      recibos: [],
      autorizados: [],

      modalEditar: false,
      modalABM: false,
      modalNuevo: false,
      modalPDF: false,
      modalAuditar: false,
      modalAnular: false,
      modalVerDetalles: false,
      modalABMRetira: false,

      tipos_combustible: [],
      destinos: [],

      reciboSeleccionado: {},

      plantilla: {},
      pdf: {
        data: null,
        nombre: '',
        titulo: '',
        zoom: 1.0
      },


      sucursalesFiltradas: [],
      estados: [],
      filtro: {
        familia: null,
        marca: null,
        sucursal: null,
        sector: null,
        patente: '',
        cliente: '',
        vin: '',
        numero: null,
        fecha_desde: '',
        fecha_hasta: ''
      },

      headers: [
        { text: 'Número', value: 'numeroFormateado' },
        { text: 'Empresa', value: 'nombre_familia' },
        { text: 'Marca', value: 'nombre_empresa' },
        { text: 'Sucursal', value: 'nombre_sucursal' },
        { text: 'Sector', value: 'nombre_sector' },
        {
          text: 'Fecha',
          value: 'Fecha',
          formatter: this.formatDate
        },
        { text: 'Usuario creación', value: 'nombre_usuario_crea' },
        { text: 'Dominio', value: 'Patente' },
        { text: 'VIN', value: 'Vin' },

        { text: 'Retira', value: 'Retira' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async buscar() {
      if (this.filtro.familia && this.filtro.marca) {

        let numero = null
        if (this.filtro.numero) {
          numero = parseInt(this.filtro.numero, 10) || null;
        }

        this.load = true
        await this.$store
          .dispatch('recibosCombustible/getRecibos', {
            familia: this.filtro.familia,
            empresa: this.filtro.marca,
            sucursal: this.filtro.sucursal,
            sector: this.filtro.sector,
            vin: this.filtro.vin,
            patente: this.filtro.patente,
            cliente: this.filtro.cliente,
            numero: numero ? numero : null,
            fecha_desde: this.filtro.fecha_desde,
            fecha_hasta: this.filtro.fecha_hasta
          })
          .then(res => {
            if (res.exito) {
              this.recibos = res.data
              this.recibos.forEach(item => {
                item.numeroFormateado = item.Id.toString().padStart(8, '0');
              });

              this.recibos = this.recibos.sort((a,b) => b.Id - a.Id)
            }
          })
        this.load = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar Empresa.',
          color: 'warning'
        })
      }
    },
    async abrirModalAsignar() {
      this.modalAsignar = true
    },

    abrirModalNuevoTalonario() {
      this.modalNuevoTalonario = true
    },

    abrirModalEditar(item) {
      this.reciboSeleccionado = item
      this.modalEditar = true
    },

    abrirModalAnular(item) {
      this.formulario = item
      this.modalAnular = true
    },

    abrirModalNuevoRecibo() {
      this.modalNuevo = true
    },

    abrirModalABM() {
      this.modalABM = true
    },
    abrirModalABMRetira() {
      this.modalABMRetira = true
    },

    abrirModalAuditar(item) {
      this.modalAuditar = true
      this.reciboSeleccionado = item
    },

    abrirModalAnular(item) {
      this.reciboSeleccionado = item
      this.modalAnular = true
    },

    abrirModalVerDetalle(item) {
      this.reciboSeleccionado = item
      this.modalVerDetalles = true
    },

    setEmpresas() {
      const empresa = this.empresas.filter(
        emp => emp.familia === this.filtro.familia
      )
      empresa.length === 1 ? (this.filtro.marca = empresa[0].id) : null
    },

    setSucursal() {
      const sucursal = this.sucursales.filter(
        suc => suc.familia_id === this.filtro.familia
      )
      sucursal.length === 1 ? (this.filtro.sucursal = sucursal[0].id) : null
    },

    limpiarFiltro() {
      this.filtro = {
        familia: null,
        sucursal: null,
        numero: null,
        nroHasta: null,
        sucursal: null,
        validoDesde: '',
        validoHasta: '',
        tipo: null
      }
    },

    formatDate(date, format) {
      if (!date) return ''
      return moment.utc(date).format(format ? format : 'DD/MM/YYYY')
    },

    async handleActualizar(event) {
      if (event) {
        this.buscar()
      }
    },

    async handleVerPdf(event) {
      const data = event.data.buffer
      this.pdf.data = data
      this.pdf.nombre = event.nombre
      this.pdf.titulo = event.titulo
      this.modalPDF = true
    },


    async descargarReciboPDF(item) {
      if (item.Voucher) {
        this.$store.state.loading = true
        await this.$store.dispatch('vouchersCombustible/getPlantilla').then(res => {
          if (res.exito) {
            this.plantilla = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })

        let voucher = {}
        await this.$store
          .dispatch('vouchersCombustible/getVoucher', {
            familia: item.Familia_id,
            empresa: item.Empresa_id,
            sucursal: item.Sucursal_id
          }).then(res => {
            if (res.exito) {
              voucher = res.data
            }
          })

        const patente = item.Patente ? item.Patente : null
        const vin = item.Vin ? item.Vin : null
        const fecha = item.Fecha ? moment.utc(item.Fecha).format('DD/MM/YYYY') : null

        const importe = `$${item.Monto}`
        const numeroFormateado = item.Id.toString().padStart(8, '0');
        const numero = `N° 0001 - ${numeroFormateado}`

        const direccion = voucher.Direccion // TRAER INFO DEL VOUCHER

        let textoFechaVencimiento = null
        if (item.Fecha_vencimiento) {
          textoFechaVencimiento = `Vigencia hasta: ${moment.utc(item.Fecha_vencimiento).format('DD/MM/YYYY')}`
        }

        if (Object.keys(voucher).length > 0 && Object.keys(this.plantilla).length > 0) {
          this.pdf.data = await pdfVoucherCombustible(this.plantilla.url, voucher.Logo_empresa, voucher.Logo_familia, voucher.Logo_estacion, importe, numero, direccion, patente, vin, fecha, textoFechaVencimiento)
          this.pdf.data = this.pdf.data.buffer
          this.pdf.titulo = `Voucher - ${numero}`
          this.pdf.nombre = this.pdf.titulo
          this.pdf.zoom = 0.4
          this.modalPDF = true
        } else {
          // arrojar error al no obtener el voucher designado para el item seleccionado
        }
        this.$store.state.loading = false

      } else {
        // DESCARGAR ORDEN DE COMPRA
        this.$store.state.loading = true

        await this.$store.dispatch('recibosCombustible/getPlantillaOrdenDeCompra').then(async res => {
          if (res.exito) {
            this.plantilla = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
          const numeroDeOrden = item.Id.toString().padStart(8, '0');
          const url = this.plantilla.url
          const fecha = moment.utc(item.Fecha).format('DD/MM/YYYY')

          const retira = this.autorizados.find(aut => (aut.Retira_id === Number(item.Retira) || aut.Retira_id == item.Retira_id))?.Nombre || item.Retira

          const nombre_tipo_combustible = this.tipos_combustible.find(tip => tip.Id === item.Tipo_combustible_id)?.Nombre || null
          const tipo_combustible = nombre_tipo_combustible ? `Tipo de combustible: ${nombre_tipo_combustible}` : null

          let nombre_sector = this.sectores.find(sec => sec.Id === item.Sector_id)?.Nombre || null
          const sector = `Sector: ${nombre_sector}`

          const litros = item.Litros === -1 ? 'Tanque lleno' : `${item.Litros} Litros`

          const dominio = item.Patente ? `Dominio: ${item.Patente}` : null
          const destino = item.nombre_destino ? `${item.nombre_destino}` : null
          const vin = item.Vin ? `VIN: ${item.Vin}` : null

          const observaciones = item.Observaciones ? `Observación: ${item.Observaciones}` : null

          const infoDetalle = {
            litros,
            tipo_combustible,
            sector,
            dominio,
            vin,
            destino,
            observaciones
          }

          let infoOrden = null
          await this.$store.dispatch('recibosCombustible/getInfoOrden', {
            familia: item.Familia_id,
            sucursal: item.Sucursal_id
          }).then(res => {
            if (res.exito) {
              infoOrden = res.data

              let inicioActividad = null
              res.data.Inicio_actividad ? inicioActividad = moment.utc(res.data.Inicio_actividad).format('MM/YYYY') : null

              infoOrden = {
                familia_id: res.data.familia_id,
                razon_social: res.data.Razon_social || '',
                cuit: res.data.Cuit || '',
                ingresos_brutos: res.data.Ing_brutos || '',
                inicio_actividad: inicioActividad,
                logo_familia: res.data.Logo_familia,
                logo_empresa: res.data.Logo_marca,
                direccion_sucursal: res.data.Domicilio,
                telefono: res.data.Telefono,
                persona_autorizada: res.data.nombre_usuario,
              }

            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
          this.pdf.data = await pdfOrdenDeCompra(url, fecha, numeroDeOrden, retira, infoOrden, infoDetalle)
          this.$store.state.loading = false

          this.pdf.data = this.pdf.data.buffer
          this.pdf.titulo = `Orden de compra - ${numeroDeOrden}`
          this.pdf.nombre = this.pdf.titulo
          this.pdf.zoom = 1.0
          this.modalPDF = true

        })
      }
    },

    async descargar() {

      if (this.recibos.length == 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes filtrar para descargar el archivo.',
          color: 'warning'
        })
        return
      }
      const cabecera = [[
        'AÑO',
        'PERIODO',
        'FECHA',
        'ORDEN NRO',
        'RETIRA',
        'VOUCHER',
        'CANTIDAD (LITROS)',
        'TIPO COMBUSTIBLE',
        'PTO VTA FAC',
        'NRO FACTURA',
        'SECTOR',
        'DESTINO',
        'VIN',
        'DOMINIO',
        'KM',
        'MONTO',
        'ESTADO DE PAGO',
        'IMPORTE FACTURA'
      ]]

      const ws = utils.aoa_to_sheet(cabecera)
      const array = this.recibos.map(recibo => {
        const fecha_format = moment.utc(recibo.Fecha)
        const fecha = fecha_format.format('DD/MM/YYYY');
        const anio = fecha_format.format('YYYY');
        const periodo = `01/${fecha_format.format('MMM')}`;

        const orden_nro = recibo.Id
        const retira = recibo.Retira ? recibo.Retira : this.autorizados.find(aut => aut.Retira_id === recibo.Retira_id)?.Nombre
        const voucher = recibo.Voucher ? 'Si' : ''
        const cantidad = recibo.Voucher ? '' : recibo.Litros === -1 ? 'Lleno' : `${recibo.Litros}`

        const tipo_combustible = recibo.Tipo_combustible_id ? this.tipos_combustible.find(tip => tip.Id === recibo.Tipo_combustible_id)?.Nombre : ''

        const factura = recibo.Nro_factura ? recibo.Nro_factura : null;
        const parts = factura ? factura.split("-") : null

        const pto_venta_factura = parts ? parts[0] : '';
        const nro_factura = parts ? parts[1] : '';

        const sector = recibo.Sector_id ? this.sectores.find(sec => sec.Id === recibo.Sector_id)?.Nombre : ''
        const destino = recibo.Empresa_comb_id ? this.destinos.find(des => des.Empresa_comb_id === recibo.Empresa_comb_id)?.Nombre : ''
        const vin = recibo.Vin ? recibo.Vin : ''
        const dominio = recibo.Patente ? recibo.Patente : ''
        const km = recibo.Km ? recibo.Km : ''
        const monto = recibo.Monto ? recibo.Monto : ''
        const estado_pago = ''

        const importe = recibo.Importe ? recibo.Importe : ''

        return {
          anio,
          periodo,
          fecha,
          orden_nro,
          retira,
          voucher,
          cantidad,
          tipo_combustible,
          pto_venta_factura,
          nro_factura,
          sector,
          destino,
          vin,
          dominio,
          km,
          monto,
          estado_pago,
          importe
        }
      })

      utils.sheet_add_json(ws, array, {
        header: ['anio', 'periodo', 'fecha', 'orden_nro', 'retira', 'voucher', 'cantidad', 'tipo_combustible', 'pto_venta_factura', 'nro_factura', 'sector', 'destino', 'vin', 'dominio', 'km', 'monto', 'estado_pago', 'importe'],
        skipHeader: true,
        origin: -1
      })

      const columnas = Object.keys(array[0])
      columnas.forEach(columna => {
        const maxLength = array.reduce(
          (max, row) => Math.max(max, String(row[columna]).length),
          0
        )
        ws['!cols'] = ws['!cols'] || []
        ws['!cols'].push({ wch: maxLength + 2 })
      })

      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Reporte')
      const wbout = write(wb, { bookType: 'xlsx', type: 'array' })
      let name =
        `Reporte_Recibos_Combustible_${moment.utc().format('DD/MM/YYYY')}` +
        '.xlsx'
      FileSaver.saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        name
      )
    }
  },

  computed: {
    ...mapState('genericos', ['sucursales']),
    ...mapState(['familias', 'empresas'])
  },

  async created() {

    await this.$store.dispatch('recibosCombustible/getPermisos').then(res => {
      if (res.exito) {
        this.permiso_auditar = res.permiso_auditar
        this.permiso_descargar = res.permiso_descargar
        this.permiso_abm_retira = res.permiso_abm_retira
      }
    })

    if (this.familias.length == 1) this.filtro.familia = this.familias[0].id
    this.empresasFiltradas = this.empresas
    await this.$store.dispatch('genericos/get_sucursales')
    this.sucursalesFiltradas = this.sucursales
    await this.$store.dispatch('recibosCombustible/getSectores').then(res => {
      if (res.exito) {
        this.sectores = res.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error'
        })
      }
    })

    await this.$store
      .dispatch('recibosCombustible/getTiposCombustible')
      .then(res => {
        if (res.exito) {
          this.tipos_combustible = res.data
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'warning'
          })
        }
      })

    await this.$store.dispatch('recibosCombustible/getAutorizadosHabilitados').then(res => {
      if (res.exito) {
        this.autorizados = res.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error'
        })
      }
    }),

      await this.$store.dispatch('recibosCombustible/getDestinos').then(res => {
        if (res.exito) {
          this.destinos = res.data
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })

  },

  components: {
    FechaPicker,
    BtnFiltro,
    BtnConfirmar,
    ABMVouchers,
    NuevoReciboCombustible,
    PDFViewer,
    AuditarRecibo,
    EditarRecibo,
    AnularRecibo,
    DetallesRecibo,
    ABMRetira,
    SearchDataTable,
    FechaPicker
  }
}
</script>
