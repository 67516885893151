import jsPDF from 'jspdf'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { NumerosALetras } from 'numero-a-letras'
import moment from 'moment'
import {
  CHEVROLET,
  CITROEN,
  PEUGEOT,
  FIRMA_JR,
  FIRMA_JR_GDB
} from '../../assets/logos'
import {
  format_money,
  format_str,
  parseDate,
  NumeroALetra,
  convertDecimals
} from '../utils'
import config from '../../../config'

export async function get_pdf(url_firebase) {
  // devuelve los bytes del pdf que esta almacenado en la api
  const pdfBytes = await fetch(url_firebase).then(res => res.arrayBuffer())
  return pdfBytes
}

export async function loadImage(url) {
  const response = await fetch(url)
  const imageBytes = await response.arrayBuffer()
  return imageBytes
}

export async function get_pdf_jarvis(nombre) {
  let jarvis_token = window.localStorage.getItem('jarvis_token')
  // devuelve los bytes del pdf que esta almacenado en la api
  const pdfBytes = await fetch(`${config.API_JARVIS}/pdf?nombre=${nombre}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: jarvis_token
    }
  }).then(res => res.arrayBuffer())

  return pdfBytes
}

// si es muy largo un nombre lo recorta en la 'longMax' y devuelve 2 partes
function salto_linea(nombre, longMax) {
  if (nombre.length > longMax) {
    let indices = []
    // carga un array con los indices donde hay espacios
    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === ' ') indices.push(i)
    }
    // calcula cual es el indice que mas se aproxima a la longitud maxima permitida
    let sub = indices.filter(i => i < longMax)
    const mitad = sub[sub.length - 1]
    return {
      parte1: nombre.substring(0, mitad),
      parte2: nombre.substring(mitad + 1, nombre.length)
    }
  } else {
    return {
      parte1: nombre,
      parte2: ''
    }
  }
}

function split_varchar(varchar, longitud_maxima) {
  if (varchar.length > longitud_maxima) {
    let result = []
    let string = ''
    let cadena = varchar.split(' ')

    for (const palabra of cadena) {
      let aux = string + palabra
      if (aux.length > longitud_maxima) {
        result.push(string)
        string = `${palabra} `
      } else {
        string += `${palabra} `
      }
    }
    if (string.trim() != '') result.push(string)
    return result
  } else {
    return [varchar]
  }
}

export async function informeComisiones(data) {
  // defino las medidas de la hoja
  const margin_x = 20 // margenes laterales de 2 cm
  const margin_y = 10 // margenes superiores de 1 cm

  // creo la primer hoja del pdf (por defecto genera una a4 de 21 x 29,7)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  ////**  HEADER DE LA TABLA  *////
  /*const header = [
    { id: 'preimpreso', name: 'preimpreso', prompt: 'Suscrip.', width: 22, align: 'center' },
    { id: 'grupo_orden', name: 'grupo_orden', prompt: 'Contrato', width: 22, align: 'left' },
    { id: 'empresa_nombre', name: 'empresa_nombre', prompt: 'Marca', width: 22, align: 'left' },
    { id: 'fecha_ingreso', name: 'fecha_ingreso', prompt: 'Fecha Ingreso', width: 24, align: 'left' },
    { id: 'titular', name: 'titular', prompt: 'Titular', width: 50, align: 'left' },
    { id: 'descripcion', name: 'descripcion', prompt: 'Vehículo', width: 50, align: 'left' },
    { id: 'porcentaje', name: 'porcentaje', prompt: 'Porc.', width: 15, align: 'center' },
    { id: 'importe', name: 'importe', prompt: 'Importe', width: 30, align: 'right' },
    { id: 'debito', name: 'debito', prompt: 'Déb. Aut.', width: 13, align: 'center' },
    { id: 'pagada_vencida', name: 'pagada_vencida', prompt: 'Pag / Ven', width: 15, align: 'center' }
  ]*/
  const header = [
    {
      id: 'titular',
      name: 'titular',
      prompt: 'Titular',
      width: 160,
      align: 'left'
    },
    {
      id: 'importe',
      name: 'importe',
      prompt: 'Importe',
      width: 60,
      align: 'right'
    }
  ]

  for (let index = 0; index < data.length; index++) {
    const item = data[index]

    // siempre que no sea la primer pagina del documento empiza con un salto de pagina
    if (index !== 0) {
      doc.addPage()
    }

    ////**  DATOS DE LA CABECERA  *////
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(10)
    //doc.text(`${item.periodo.toUpperCase()}`, margin_x, margin_y + 5)
    //doc.text(`Supervisor: ${item.supervisor}`, margin_x, margin_y + 10)
    //doc.text(`Vendedor: ${item.vendedor}`, margin_x, margin_y + 15)
    doc.text(`Total: ${item.total}`, margin_x, margin_y + 7) // 7 -> antes 22

    ////**  TABLA  *////
    doc.table(margin_x, margin_y + 10, item.detalle, header, {
      autoSize: false,
      fontSize: 10,
      padding: 1,
      headerBackgroundColor: '#e1e1e1'
    }) // 10 -> antes 25
  }

  // devuelve los bytes del pdf generado
  return doc.output('arraybuffer')
}

export async function formularioNoRodado(data) {
  // defino las medidas de la hoja
  const width = 210 // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margin_x = 20 // margenes laterales de 2 cm
  const margin_y = 20 // margenes superiores de 2 cm

  // creo la primer hoja del pdf (por defecto genera una a4 de 21 x 29,7)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  const sucursal = data.sucursal

  if (data.empresa == 1) {
    doc.addImage(CHEVROLET, 'PNG', margin_x, margin_y, 60, 14)
  } else {
    doc.addImage(
      data.empresa != 4 ? CITROEN : PEUGEOT,
      'PNG',
      margin_x,
      margin_y,
      80,
      10
    )
  }
  doc.addImage(
    data.empresa == 1 ? FIRMA_JR : FIRMA_JR_GDB,
    'PNG',
    130,
    margin_y + 100,
    40,
    40
  )
  doc.setFont('times')
  doc.setFontSize(12)
  doc.text(
    `${sucursal.provincia}, ${moment(new Date())
      .locale('es')
      .format('DD [de] MMMM [del] YYYY')}`,
    130,
    margin_y + 30
  )
  if (data.no_rodado != '') {
    const no_rodado = data.no_rodado.split('\n')
    doc.text(no_rodado[0], margin_x, margin_y + 50)
    doc.text(split_varchar(no_rodado[1], 80)[0], margin_x, margin_y + 55.25)
    let y = 60.5
    for (
      let index = 1;
      index < split_varchar(no_rodado[1], 80).length;
      index++
    ) {
      const linea = split_varchar(no_rodado[1], 80)[index]
      doc.text(linea, margin_x, margin_y + y)
      y += 5.25
    }
  }
  doc.text(
    `Queda Ud. debidamente notificad${data.sexo == 'F' ? 'a' : 'o'}`,
    120,
    margin_y + 90
  )
  doc.text('Firma:', 110, margin_y + 110)
  doc.text('Aclaración:', 101.5, margin_y + 120)
  doc.text(
    'Sin más saludo a Ud. muy cordialmente.',
    120,
    height - margin_y - 15
  )
  doc.line(
    margin_x,
    height - margin_y - 4,
    width - margin_x,
    height - margin_y - 4
  )
  doc.setFont('helvetica')
  doc.setFontSize(8)
  doc.text(
    `${sucursal.domicilio} - Tel.: ${sucursal.telefono}`,
    70,
    height - margin_y
  )

  // devuelve los bytes del pdf generado
  return doc.output('arraybuffer')
}

export async function formulario03(data) {
  // defino las medidas de la hoja
  const width = 202 // ancho de 20,5 cm
  const height = 305 // alto de 30,5 cm
  const x_diff = 4
  const y_diff = 1

  // genero el pdf
  let doc = new jsPDF({ putOnlyUsedFonts: false, format: [width, height] })

  // fecha del contrato
  let fecha = data.fecha_03.split('/')
  doc.setFont('helvetica', 'bold')
  doc.setFontSize(9)
  doc.text(fecha[0], 51 + x_diff, 43 + y_diff)
  doc.text(fecha[1], 59 + x_diff, 43 + y_diff)
  doc.text(fecha[2].substring(2, 4), 68 + x_diff, 43 + y_diff)

  // monto y patente
  doc.text(format_money(data.monto_prenda), 47 + x_diff, 50 + y_diff)
  doc.setFontSize(16)
  doc.text(format_str(data.patente), 97 + x_diff, 50 + y_diff)
  doc.setFontSize(9)

  const financiera = data.financiera

  // DATOS DE LA FINANCIERA
  doc.text(format_str(financiera.numero_inscripcion), 75 + x_diff, 68 + y_diff)
  doc.text(
    format_str(financiera.nombre).toUpperCase(),
    45 + x_diff,
    75 + y_diff
  )
  doc.text(
    financiera.cuit ? 'CUIT: ' + financiera.cuit : '',
    45 + x_diff,
    88 + y_diff
  )
  doc.text(format_str(financiera.calle).toUpperCase(), 45 + x_diff, 97 + y_diff)
  doc.text(format_str(financiera.numero), 48 + x_diff, 105 + y_diff)
  doc.text(format_str(financiera.piso), 69 + x_diff, 105 + y_diff)
  doc.text(format_str(financiera.cp), 96 + x_diff, 105 + y_diff)
  doc.text(format_str(financiera.localidad), 70 + x_diff, 113 + y_diff)
  doc.text(
    format_str(financiera.personeria).toUpperCase(),
    55 + x_diff,
    178 + y_diff
  )
  doc.text(format_str(financiera.datos_inscripcion), 45 + x_diff, 193 + y_diff)
  // fehca de inscripcion
  if (financiera.fecha_inscripcion) {
    fecha = financiera.fecha_inscripcion.split('/')
    doc.text(fecha[0], 90 + x_diff, 193 + y_diff)
    doc.text(fecha[1], 100 + x_diff, 193 + y_diff)
    doc.text(fecha[2].substring(2, 4), 109 + x_diff, 193 + y_diff)
  }

  // DATOS DEL CLIENTE
  doc.text('CUIL: ' + format_str(data.cuil), 120 + x_diff, 68 + y_diff)
  doc.text(data.cliente.toUpperCase(), 120 + x_diff, 75 + y_diff)
  doc.text(
    'NACIONALIDAD: ' + format_str(data.nacionalidad).toUpperCase(),
    120 + x_diff,
    83 + y_diff
  )
  doc.text(
    'OCUPACIÓN: ' + format_str(data.ocupacion).toUpperCase(),
    120 + x_diff,
    88 + y_diff
  )

  doc.text(format_str(data.calle).toUpperCase(), 120 + x_diff, 97 + y_diff)
  doc.text(format_str(data.numero), 122 + x_diff, 105 + y_diff)
  doc.text(format_str(data.piso), 144 + x_diff, 105 + y_diff)
  doc.text(format_str(data.dep), 157 + x_diff, 105 + y_diff)
  doc.text(format_str(data.cp), 174 + x_diff, 105 + y_diff)
  doc.text(format_str(data.localidad).toUpperCase(), 135 + x_diff, 113 + y_diff)
  doc.text(
    format_str(data.departamento).toUpperCase(),
    120 + x_diff,
    121 + y_diff
  )
  doc.text(format_str(data.provincia).toUpperCase(), 170 + x_diff, 121 + y_diff)

  // tipo de documento
  let position = 0
  switch (data.tipo_documento) {
    case 'DNI':
      const nacionalidad = format_str(data.nacionalidad).trim().toLowerCase()
      if (nacionalidad == 'argentina' || nacionalidad == 'argentino')
        position = 126
      else position = 164
      break
    case 'LE':
      position = 137
      break
    case 'LC':
      position = 148
      break
    case 'CI':
      position = 175
      break
    case 'Pasaporte':
      position = 185
      break
  }
  doc.text('X', position + x_diff, 138 + y_diff)

  doc.text(format_str(data.dni), 125 + x_diff, 146 + y_diff)
  doc.text(
    format_str(data.autoridad).toUpperCase() + '    SEXO: ' + data.sexo,
    150 + x_diff,
    146 + y_diff
  )

  // fecha de nacimiento
  fecha = format_str(data.nacimiento).split('/')
  doc.text(format_str(fecha[0]), 120 + x_diff, 162 + y_diff)
  doc.text(format_str(fecha[1]), 129 + x_diff, 162 + y_diff)
  doc.text(format_str(fecha[2]).substring(2, 4), 137 + x_diff, 162 + y_diff)

  // estado civil
  position = 0
  switch (data.estado_civil) {
    case 'Soltero':
      position = 148
      break
    case 'Casado':
      position = 158
      doc.text(format_str(data.nupcia), 188 + x_diff, 162 + y_diff)
      doc.text(
        format_str(data.conyuge).toUpperCase(),
        120 + x_diff,
        170 + y_diff
      )
      break
    case 'Viudo':
      position = 169
      break
    case 'Divorciado':
      position = 180
      break
  }
  doc.text('X', position + x_diff, 162 + y_diff)

  // DATOS DEL VEHICULO
  doc.text(format_str(data.patente), 80 + x_diff, 233 + y_diff)
  doc.text(data.marca, 70 + x_diff, 240 + y_diff)
  doc.text(data.tipo.toUpperCase(), 60 + x_diff, 247 + y_diff)
  doc.setFontSize(8)
  doc.text(data.modelo, 56 + x_diff, 254 + y_diff)
  doc.setFontSize(9)
  doc.text(data.marca_motor, 75 + x_diff, 261 + y_diff)
  doc.text(format_str(data.motor), 70 + x_diff, 268 + y_diff)
  doc.text(data.marca_chasis, 75 + x_diff, 275 + y_diff)
  doc.text(format_str(data.chasis), 70 + x_diff, 282 + y_diff)

  // otros
  doc.text('X', 158 + x_diff, 233 + y_diff)
  doc.text('X', 179 + x_diff, 265 + y_diff)
  doc.text('1º', 130 + x_diff, 269 + y_diff)
  doc.text('X', 179 + x_diff, 277 + y_diff)

  // devuelve los bytes del pdf generado
  return doc.output('arraybuffer')
}

export async function formularioPrendaGPAT(data) {
  // defino las medidas de la hoja
  const width = 212 // ancho de 21,2 cm
  const height = 320 // alto de 32 cm
  let x_diff = 0
  const y_diff = 4

  // genero el pdf
  let doc = new jsPDF({ putOnlyUsedFonts: false, format: [width, height] })

  const sucursal = data.sucursal

  function hoja() {
    // monto, fecha y cliente
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    doc.text(format_money(data.monto_prenda), 65 + x_diff, 66 + y_diff)
    doc.setFontSize(8)
    doc.text(sucursal.provincia.toUpperCase(), 145 + x_diff, 58 + y_diff)
    let fecha = data.fecha_prenda.split('/')
    doc.text(fecha[0], 160 + x_diff, 58 + y_diff)
    doc.setFontSize(7)
    doc.text(
      moment(parseDate(data.fecha_prenda))
        .locale('es')
        .format('MMMM')
        .toUpperCase(),
      171 + x_diff,
      58 + y_diff
    )
    doc.setFontSize(8)
    doc.text(fecha[2], 188 + x_diff, 58 + y_diff)
    const monto = salto_linea(NumeroALetra(data.monto_prenda), 60)
    doc.text(monto.parte1.toUpperCase(), 100 + x_diff, 71 + y_diff)
    if (monto.parte2 == '') {
      doc.line(
        195 - (70 - monto.parte1.length) + x_diff,
        70 + y_diff,
        195 + x_diff,
        70 + y_diff
      )
    }
    doc.text(monto.parte2.toUpperCase(), 35 + x_diff, 75 + y_diff)
    doc.line(
      35 + (monto.parte2 == '' ? 0 : 40 + monto.parte2.length) + x_diff,
      74 + y_diff,
      195 + x_diff,
      74 + y_diff
    )
    doc.text(data.cliente.toUpperCase(), 55 + x_diff, 79 + y_diff)
    doc.line(
      195 - (110 - data.cliente.length) + x_diff,
      78 + y_diff,
      195 + x_diff,
      78 + y_diff
    )

    // datos del auto
    doc.text(data.marca, 60 + x_diff, 95 + y_diff)
    doc.text(data.tipo.toUpperCase(), 95 + x_diff, 95 + y_diff)
    doc.text('*', 130 + x_diff, 95 + y_diff)
    doc.text(data.marca_motor, 175 + x_diff, 95 + y_diff)
    doc.text(format_str(data.motor), 42 + x_diff, 99 + y_diff)
    doc.text(data.marca_chasis, 115 + x_diff, 99 + y_diff)
    doc.text(format_str(data.chasis), 174 + x_diff, 99 + y_diff)
    doc.text('* ' + data.modelo, 128 + x_diff, 103 + y_diff)

    // datos del domicilio
    doc.text(sucursal.provincia.toUpperCase(), 90 + x_diff, 111 + y_diff)
    doc.text(
      format_str(data.localidad).toUpperCase(),
      140 + x_diff,
      111 + y_diff
    )
    doc.line(67 + x_diff, 114 + y_diff, 83 + x_diff, 114 + y_diff)
    doc.line(146 + x_diff, 114 + y_diff, 195 + x_diff, 114 + y_diff)
    doc.line(35 + x_diff, 118 + y_diff, 65 + x_diff, 118 + y_diff)
    doc.text(
      format_str(data.departamento).toUpperCase(),
      95 + x_diff,
      119 + y_diff
    )
    doc.text(format_str(data.calle).toUpperCase(), 129 + x_diff, 119 + y_diff)
    doc.text(format_str(data.numero).toUpperCase(), 185 + x_diff, 119 + y_diff)

    // datos financieros
    doc.line(35 + x_diff, 126 + y_diff, 195 + x_diff, 126 + y_diff)
    doc.text(`${format_str(data.cuotas)} CUOTAS`, 182 + x_diff, 131 + y_diff)
    doc.text(
      `MENSUALES, IGUALES Y CONSECUTIVAS DE ${format_money(
        data.valor_cuota
      )} CADA UNA`,
      40 + x_diff,
      135 + y_diff
    )
    doc.line(140 + x_diff, 134 + y_diff, 195 + x_diff, 134 + y_diff)
    doc.text(format_str(data.cuotas), 129 + x_diff, 139 + y_diff)
    doc.text(
      format_money(data.valor_cuota).substring(
        2,
        format_money(data.valor_cuota).length
      ),
      59 + x_diff,
      143 + y_diff
    )
    fecha = data.fecha_vencimiento.split('/')
    doc.text(fecha[0], 140 + x_diff, 147 + y_diff)
    doc.text(
      moment(parseDate(data.fecha_vencimiento))
        .locale('es')
        .format('MMMM')
        .toUpperCase(),
      156 + x_diff,
      147 + y_diff
    )
    doc.text(fecha[2], 190 + x_diff, 147 + y_diff)
    doc.text(format_str(data.interes), 105 + x_diff, 157 + y_diff)
    doc.text(format_str(data.tasa), 165 + x_diff, 157 + y_diff)
    doc.text(format_str(data.costo_financiero_ci), 64 + x_diff, 161 + y_diff)
    doc.text(format_str(data.costo_financiero_si), 148 + x_diff, 161 + y_diff)

    // datos del cliente
    doc.text(data.cliente.toUpperCase(), 147 + x_diff, 208 + y_diff)
    doc.text(
      format_str(data.estado_civil).toUpperCase(),
      140 + x_diff,
      212 + y_diff
    )
    doc.text(
      format_str(data.ocupacion).toUpperCase(),
      138 + x_diff,
      216 + y_diff
    )
    doc.text(format_str(data.cuil), 145 + x_diff, 220 + y_diff)
    doc.text(
      format_str(data.nacionalidad).toUpperCase(),
      142 + x_diff,
      228 + y_diff
    )
    doc.text(
      moment(new Date()).diff(parseDate(data.nacimiento), 'years').toString(),
      180 + x_diff,
      228 + y_diff
    )
    doc.setFontSize(7)
    const domicilio = salto_linea(
      `${format_str(data.calle)} ${format_str(data.numero)} ${format_str(
        data.piso
      )} ${format_str(data.dep)} ${format_str(data.localidad)} - ${format_str(
        data.departamento
      )} - ${format_str(data.provincia)}`.toUpperCase(),
      55
    )
    doc.text(
      domicilio.parte2 ? domicilio.parte1 + ' **' : domicilio.parte1,
      135 + x_diff,
      232 + y_diff
    )
    if (domicilio.parte2) {
      doc.text('** ' + domicilio.parte2, 125 + x_diff, 240 + y_diff)
    }
    doc.setFontSize(8)
    doc.text(
      `${format_str(data.tipo_documento)} ${format_str(
        data.dni
      )}`.toUpperCase(),
      156 + x_diff,
      236 + y_diff
    )

    // PAGINA DE ATRAS DEL FORMULARIO (solo si es casado)
    if (data.estado_civil == 'Casado') {
      doc.addPage()
      doc.text(format_str(data.conyuge).toUpperCase(), 35, 20 + y_diff)
      doc.text(format_str(data.estado_civil).toUpperCase(), 100, 20 + y_diff)
      doc.text(
        format_str(data.nacionalidad_conyuge).toUpperCase(),
        135,
        20 + y_diff
      )
      doc.text(format_str(data.edad_conyuge), 165, 20 + y_diff)
      doc.text(
        `${format_str(data.calle)} ${format_str(data.numero)} ${format_str(
          data.piso
        )} ${format_str(data.dep)} ${format_str(data.localidad)} - ${format_str(
          data.departamento
        )} - ${format_str(data.provincia)}`.toUpperCase(),
        30,
        23 + y_diff
      )
      doc.text(format_str(data.documento_conyuge), 40, 40 + y_diff)
    }
  }

  // genera la hoja del origial
  x_diff = 0
  hoja()
  // genera las copias
  x_diff = -2
  doc.addPage()
  hoja()
  doc.addPage()
  hoja()

  // devuelve los bytes del pdf generado
  return doc.output('arraybuffer')
}

export async function formularioPrendaCitroenPSA(data) {
  // defino las medidas de la hoja
  const x_diff = 0
  const y_diff = 6

  // genero el pdf (por defecto genera una a4 de 21 x 29,7)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  const sucursal = data.sucursal

  function hoja_1() {
    // monto y fecha
    doc.setFontSize(8)
    doc.text(format_money(data.monto_prenda), 50 + x_diff, 30 + y_diff)
    doc.text(
      `${sucursal.provincia.toUpperCase()} ${moment(
        parseDate(data.fecha_prenda)
      )
        .locale('es')
        .format('DD [de] MMMM')
        .toUpperCase()}`,
      135 + x_diff,
      30 + y_diff
    )
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('YY'),
      185 + x_diff,
      30 + y_diff
    )
    const monto = salto_linea(
      `${NumeroALetra(data.monto_prenda).toUpperCase()}. -`,
      60
    )
    doc.text(monto.parte1, 100 + x_diff, 38 + y_diff)
    doc.text(monto.parte2, 30 + x_diff, 43.5 + y_diff)
    doc.text(data.cliente.toUpperCase(), 45 + x_diff, 48 + y_diff)
    doc.text('PRESTAMO DINERO EN EFECTIVO. -', 70 + x_diff, 52.5 + y_diff)

    // auto
    doc.text('UN AUTOMOVIL MARCA', 155 + x_diff, 62 + y_diff)
    doc.text(
      `${data.marca}, TIPO ${data.tipo.toUpperCase()}, MODELO ${
        data.modelo
      }, MARCA MOTOR ${data.marca_motor}`,
      28 + x_diff,
      66 + y_diff
    )
    doc.text(
      `Nº ${data.motor}, MARCA CHASIS ${data.marca_chasis} Nº ${
        data.chasis
      }, USO ${format_str(data.uso).toUpperCase()}, AÑO MODELO ${format_str(
        data.anio
      )}. -`,
      28 + x_diff,
      71 + y_diff
    )

    // direccion
    doc.text(data.provincia.toUpperCase(), 75 + x_diff, 94 + y_diff)
    doc.text(
      format_str(data.departamento).toUpperCase(),
      134 + x_diff,
      94 + y_diff
    )
    doc.text(format_str(data.localidad).toUpperCase(), 44 + x_diff, 98 + y_diff)
    doc.text(format_str(data.calle).toUpperCase(), 122 + x_diff, 107.5 + y_diff)
    doc.text(
      format_str(data.numero).toUpperCase(),
      180 + x_diff,
      107.5 + y_diff
    )

    // texto
    doc.text('NINGUNO DE NINGUNA', 155 + x_diff, 112 + y_diff)
    doc.text('NATURALEZA. -', 28 + x_diff, 116.5 + y_diff)
    doc.text(
      'ESTE CONTRATO PRENDARIO Y SUS CONTINUACIONES',
      110 + x_diff,
      121 + y_diff
    )
    doc.text(
      `PAGADAS ${format_str(
        data.cuotas
      )} EN CUOTAS MENSUALES Y CONSECUTIVAS. -`,
      28 + x_diff,
      126 + y_diff
    )
    doc.text(
      `LA PRIMERA EL ${moment(parseDate(data.fecha_prenda)).format(
        'DD/MM/YYYY'
      )} Y`,
      120 + x_diff,
      131 + y_diff
    )
    doc.text(
      `EL MISMO ${moment(parseDate(data.fecha_prenda)).format(
        'DD'
      )} DE LOS MESES SUBSIGUIENTES. -`,
      28 + x_diff,
      135 + y_diff
    )

    doc.text(`${format_str(data.interes)}`, 100 + x_diff, 144 + y_diff)
    doc.text('AMPLIOS', 142 + x_diff, 148.5 + y_diff)

    // cliente
    doc.text(data.cliente.toUpperCase(), 140 + x_diff, 181 + y_diff)
    doc.text(
      format_str(data.estado_civil).toUpperCase(),
      132 + x_diff,
      185.5 + y_diff
    )
    doc.text(
      format_str(data.ocupacion).toUpperCase(),
      170 + x_diff,
      185.5 + y_diff
    )
    doc.text(
      format_str(data.nacionalidad).toUpperCase(),
      135 + x_diff,
      190 + y_diff
    )
    doc.text(
      moment(new Date()).diff(parseDate(data.nacimiento), 'years').toString(),
      175 + x_diff,
      190 + y_diff
    )
    doc.text(
      `${format_str(data.calle)} ${format_str(data.numero)} ${format_str(
        data.piso
      )} ${format_str(data.dep)} ${format_str(data.localidad)}`.toUpperCase(),
      127 + x_diff,
      194.5 + y_diff
    )
    doc.text(format_str(data.dni).toUpperCase(), 150 + x_diff, 199 + y_diff)

    // REVERSO DE LA PRIMER HOJA (solo si es casado)
    doc.addPage()
    doc.text(
      `${format_str(data.calle)} ${format_str(data.localidad)}`.toUpperCase(),
      116 + x_diff,
      25 + y_diff
    )
    doc.text(format_str(data.numero).toUpperCase(), 16 + x_diff, 29.5 + y_diff)
    doc.text(
      `${format_str(data.departamento)} - ${format_str(
        data.provincia
      )}`.toUpperCase(),
      40 + x_diff,
      29.5 + y_diff
    )
    if (data.estado_civil == 'Casado') {
      doc.text(
        `ASENTIMIENTO CONYUGAL TITULAR ${format_str(
          data.conyuge
        )} - ${format_str(data.calle)} ${format_str(data.numero)} ${format_str(
          data.piso
        )} ${format_str(data.dep)} ${format_str(data.localidad)}`.toUpperCase(),
        15 + x_diff,
        40 + y_diff
      )
      doc.text(
        `- ${format_str(data.departamento)} - ${format_str(
          data.provincia
        )} - DNI ${format_str(data.documento_conyuge)}`.toUpperCase(),
        15 + x_diff,
        44 + y_diff
      )
      doc.text(
        format_str(data.referencia_ccc).toUpperCase(),
        15 + x_diff,
        50 + y_diff
      )
    }
  }

  // genera las 3 copias de la hoja 1
  hoja_1()
  doc.addPage()
  hoja_1()
  doc.addPage()
  hoja_1()

  const hoja1 = doc.output('arraybuffer')

  // SEGUNDA HOJA
  doc = new jsPDF({ putOnlyUsedFonts: false })

  function hoja_2() {
    doc.setFontSize(8)
    doc.text(
      'PSA FINANCE ARGENTINA COMPAÑIA FINANCIERA SA.',
      90 + x_diff,
      29 + y_diff
    )
    doc.text(data.cliente.toUpperCase(), 30 + x_diff, 32 + y_diff)
    doc.text(format_str(data.cuotas), 67 + x_diff, 43 + y_diff)
    doc.text('MENSUALES', 90 + x_diff, 43 + y_diff)
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('DD-MM-YYYY'),
      50 + x_diff,
      51 + y_diff
    )
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('DD'),
      95 + x_diff,
      51 + y_diff
    )
    doc.text(
      format_money(data.valor_cuota).substring(
        2,
        format_money(data.valor_cuota).length
      ),
      155 + x_diff,
      56 + y_diff
    )
    doc.text(
      `${NumeroALetra(data.valor_cuota).toUpperCase()}. -`,
      22 + x_diff,
      59 + y_diff
    )
    doc.text(format_str(data.costo_financiero_ci), 135 + x_diff, 143 + y_diff)

    // REVERSO DE LA SEGUNDA HOJA (solo si es casado)
    doc.addPage()
    doc.text(format_str(data.dni).toUpperCase(), 95 + x_diff, 255 + y_diff)
    if (data.estado_civil == 'Casado') {
      doc.text(
        format_str(data.conyuge).toUpperCase(),
        20 + x_diff,
        150 + y_diff
      )
      doc.text(format_str(data.documento_conyuge), 120 + x_diff, 260.5 + y_diff)
    }
  }

  // genera las 3 copias de la hoja 2
  hoja_2()
  doc.addPage()
  hoja_2()
  doc.addPage()
  hoja_2()

  // devuelve los bytes del pdf generado
  return {
    hoja1: hoja1,
    hoja2: doc.output('arraybuffer')
  }
}

export async function formularioPrendaCitroenCirculo(data) {
  // defino las medidas de la hoja
  let x_diff = 0
  let y_diff = 1.5

  // genero el pdf (legal = oficio de 21,59 x 35,56)
  let doc = new jsPDF({ putOnlyUsedFonts: false, format: 'legal' })

  const sucursal = data.sucursal

  function hoja_1() {
    // lugar y fecha
    doc.setFontSize(6)
    doc.text(
      `${sucursal.provincia.toUpperCase()} ${moment(
        parseDate(data.fecha_prenda)
      )
        .locale('es')
        .format('DD [de] MMMM')
        .toUpperCase()}`,
      135 + x_diff,
      34 + y_diff
    )
    doc.setFontSize(8)
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('YY'),
      170 + x_diff,
      34 + y_diff
    )

    // titular y vehiculo
    doc.text(data.cliente.toUpperCase(), 65 + x_diff, 65 + y_diff)
    doc.text(
      `UN AUTOMOVIL 0KM DOMINIO A PATENTAR, AÑO ${format_str(
        data.anio
      )}, MARCA ${data.marca} TIPO ${data.tipo.toUpperCase()}`,
      47 + x_diff,
      92 + y_diff
    )
    doc.text(
      `MODELO ${data.modelo} MOTOR MARCA ${data.marca_motor} Nº ${data.motor},`,
      47 + x_diff,
      95.5 + y_diff
    )
    doc.text(
      `CHASIS MARCA ${data.marca_chasis} Nº ${data.chasis}, USO ${format_str(
        data.uso
      ).toUpperCase()}`,
      47 + x_diff,
      99 + y_diff
    )

    // direccion
    doc.text(data.provincia.toUpperCase(), 100 + x_diff, 113.5 + y_diff)
    doc.text(
      format_str(data.localidad).toUpperCase(),
      60 + x_diff,
      117.5 + y_diff
    )
    doc.text(
      format_str(data.departamento).toUpperCase(),
      70 + x_diff,
      125 + y_diff
    )
    doc.text(format_str(data.calle).toUpperCase(), 120 + x_diff, 125 + y_diff)
    doc.text(format_str(data.numero).toUpperCase(), 167 + x_diff, 125 + y_diff)

    // cliente
    doc.text(data.preventa.substring(3, 7), 143 + x_diff, 208 + y_diff)
    doc.text(data.preventa.substring(8, 11), 165 + x_diff, 208 + y_diff)
    const nombre = salto_linea(data.cliente, 20)
    doc.text(nombre.parte1.toUpperCase(), 143 + x_diff, 211.5 + y_diff)
    doc.text(nombre.parte2.toUpperCase(), 120 + x_diff, 214.5 + y_diff)
    doc.text(
      format_str(data.estado_civil).toUpperCase(),
      135 + x_diff,
      220.5 + y_diff
    )
    doc.text(
      format_str(data.ocupacion).toUpperCase(),
      162 + x_diff,
      220.5 + y_diff
    )
    doc.text(
      format_str(data.nacionalidad).toUpperCase(),
      137 + x_diff,
      224 + y_diff
    )
    doc.text(
      moment(new Date()).diff(parseDate(data.nacimiento), 'years').toString(),
      163 + x_diff,
      224 + y_diff
    )
    doc.text(
      `${format_str(data.calle)} ${format_str(data.numero)} ${format_str(
        data.piso
      )} ${format_str(data.dep)} ${format_str(data.localidad)}`.toUpperCase(),
      132 + x_diff,
      227.5 + y_diff
    )
    doc.text(format_str(data.dni).toUpperCase(), 152 + x_diff, 231.5 + y_diff)
    doc.text(
      format_str(data.celular).toUpperCase(),
      140 + x_diff,
      236.5 + y_diff
    )

    // REVERSO DE LA PRIMER PAGINA
    doc.addPage()
    if (data.garante || data.estado_civil == 'Casado') {
      if (data.estado_civil == 'Casado') {
        doc.text(
          `ASENTIMIENTO CONYUGAL TITULAR ${format_str(
            data.conyuge
          ).toUpperCase()} - DNI ${format_str(
            data.documento_conyuge
          )} - ${format_str(data.calle)} ${format_str(
            data.numero
          )} ${format_str(data.piso)} ${format_str(data.dep)}`,
          20 + x_diff,
          42 + y_diff
        )
        doc.text(
          `${format_str(data.localidad).toUpperCase()} - ${format_str(
            data.departamento
          ).toUpperCase()} - ${format_str(data.provincia).toUpperCase()}.`,
          20 + x_diff,
          47.5 + y_diff
        )
        doc.text(
          format_str(data.referencia_ccc).toUpperCase(),
          20 + x_diff,
          53 + y_diff
        )
      }
      if (data.garante) {
        doc.text(
          `Codeudores solidarios: ${data.garante}, DNI ${
            format_str(data.cuil_garante).split('-')[1]
          }, ${format_str(data.estado_civil_garante)}, ${format_str(
            data.calle_garante
          ).toUpperCase()} ${format_str(data.numero_garante)} ${format_str(
            data.piso_garante
          )} ${format_str(data.dep_garante)} -`,
          20 + x_diff,
          63 + y_diff
        )
        doc.text(
          `${format_str(data.localidad_garante).toUpperCase()} - ${format_str(
            data.departamento_garante
          ).toUpperCase()} - ${format_str(
            data.provincia_garante
          ).toUpperCase()}. Edad ${format_str(
            data.edad_garante
          )} años - CUIL ${format_str(data.cuil_garante)} -`,
          20 + x_diff,
          68.5 + y_diff
        )
        doc.text(
          `${format_str(data.ocupacion_garante).toUpperCase()}.`,
          20 + x_diff,
          74 + y_diff
        )
      }
    }
  }

  // genera las 3 copias de la hoja 1
  hoja_1()
  doc.addPage()
  hoja_1()
  doc.addPage()
  hoja_1()

  const hoja1 = doc.output('arraybuffer')

  // SEGUNDA HOJA
  doc = new jsPDF({ putOnlyUsedFonts: false })

  function hoja_2() {
    y_diff = 13.5
    x_diff = 0
    doc.setFontSize(7)
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('DD-MM-YYYY'),
      115 + x_diff,
      15 + y_diff
    )
    doc.text(data.cliente.toUpperCase(), 52 + x_diff, 22 + y_diff)
    doc.text(data.marca, 138 + x_diff, 25 + y_diff)
    doc.text('*', 170 + x_diff, 25 + y_diff)
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('YYYY'),
      42 + x_diff,
      28 + y_diff
    )
    doc.text(format_str(data.motor), 78 + x_diff, 28 + y_diff)
    doc.text(format_str(data.chasis), 128 + x_diff, 28 + y_diff)
    doc.text('* ' + data.modelo, 32 + x_diff, 33.5 + y_diff)

    // REVERSO DE LA SEGUNDA HOJA
    y_diff = 8.5
    x_diff = 5
    doc.addPage()
    doc.setFontSize(6)
    if (data.estado_civil == 'Casado' && data.garante) {
      doc.text(
        format_str(data.conyuge).toUpperCase(),
        30 + x_diff,
        154 + y_diff
      )
      doc.text(format_str(data.documento_conyuge), 60 + x_diff, 157 + y_diff)
      doc.text(
        `${format_str(data.calle)} ${format_str(data.numero)} ${format_str(
          data.piso
        )} ${format_str(data.dep)} ${format_str(data.localidad)}`.toUpperCase(),
        130 + x_diff,
        157 + y_diff
      )
      doc.text(data.garante.toUpperCase(), 140 + x_diff, 160 + y_diff)
      doc.text(
        `${format_str(data.calle_garante)} ${format_str(
          data.numero_garante
        )} ${format_str(data.piso_garante)} ${format_str(
          data.dep_garante
        )} ${format_str(data.localidad_garante)}`.toUpperCase(),
        60 + x_diff,
        163 + y_diff
      )
    } else if (data.estado_civil == 'Casado') {
      doc.text(
        format_str(data.conyuge).toUpperCase(),
        30 + x_diff,
        154 + y_diff
      )
      doc.text(format_str(data.documento_conyuge), 60 + x_diff, 157 + y_diff)
      doc.text(
        `${format_str(data.calle)} ${format_str(data.numero)} ${format_str(
          data.piso
        )} ${format_str(data.dep)} ${format_str(data.localidad)}`.toUpperCase(),
        130 + x_diff,
        157 + y_diff
      )
    } else if (data.garante) {
      doc.text(data.garante.toUpperCase(), 30 + x_diff, 154 + y_diff + 0.25)
      doc.text(
        format_str(data.cuil_garante).split('-')[1],
        60 + x_diff,
        157 + y_diff
      )
      doc.text(
        `${format_str(data.calle_garante)} ${format_str(
          data.numero_garante
        )} ${format_str(data.piso_garante)} ${format_str(
          data.dep_garante
        )} ${format_str(data.localidad_garante)}`.toUpperCase(),
        130 + x_diff,
        157 + y_diff - 0.25
      )
    }

    doc.text(data.preventa.substring(3, 7), 58 + x_diff, 182.5 + y_diff)
    doc.text(data.preventa.substring(8, 11), 82 + x_diff, 182.5 + y_diff)
    doc.text(format_str(data.calle).toUpperCase(), 22 + x_diff, 188.5 + y_diff)
    doc.text(format_str(data.numero).toUpperCase(), 20 + x_diff, 191 + y_diff)
    doc.text(format_str(data.piso).toUpperCase(), 50 + x_diff, 191 + y_diff)
    doc.text(format_str(data.dep).toUpperCase(), 80 + x_diff, 191 + y_diff)
    doc.text(
      format_str(data.localidad).toUpperCase(),
      28 + x_diff,
      194 + y_diff
    )
    doc.text(data.provincia.toUpperCase(), 75 + x_diff, 194 + y_diff)

    // garante
    doc.text(
      format_str(data.calle_garante).toUpperCase(),
      22 + x_diff,
      202 + y_diff
    )
    doc.text(
      format_str(data.numero_garante).toUpperCase(),
      20 + x_diff,
      205 + y_diff
    )
    doc.text(
      format_str(data.piso_garante).toUpperCase(),
      50 + x_diff,
      205 + y_diff
    )
    doc.text(
      format_str(data.dep_garante).toUpperCase(),
      80 + x_diff,
      205 + y_diff
    )
    doc.text(
      format_str(data.localidad_garante).toUpperCase(),
      28 + x_diff,
      207.5 + y_diff
    )
    doc.text(
      format_str(data.provincia_garante).toUpperCase(),
      75 + x_diff,
      207.5 + y_diff
    )

    const calle = salto_linea(format_str(data.calle).toUpperCase(), 20)
    doc.text(calle.parte1, 160 + x_diff, 212.5 + y_diff)
    doc.text(calle.parte2, 15 + x_diff, 215 + y_diff)
    doc.text(format_str(data.numero).toUpperCase(), 100 + x_diff, 215 + y_diff)
    doc.text(
      format_str(data.localidad).toUpperCase(),
      36 + x_diff,
      218.5 + y_diff
    )
    doc.text(data.provincia.toUpperCase(), 110 + x_diff, 218.5 + y_diff)
  }

  // genera las 3 copias de la hoja 2
  hoja_2()
  doc.addPage()
  hoja_2()
  doc.addPage()
  hoja_2()

  // devuelve los bytes del pdf generado
  return {
    hoja1: hoja1,
    hoja2: doc.output('arraybuffer')
  }
}

export async function formularioSantander(data) {
  // defino las medidas de la hoja
  const width = 215 // ancho de 21,5 cm
  const height = 310 // alto de 31 cm
  const widthRio = 215 // ancho de 21,5 cm
  const heightRio = 282 // alto de 28,2 cm
  let x_diff = 0
  let y_diff = 4

  // genero el pdf (contrato)
  let doc = new jsPDF({ putOnlyUsedFonts: false, format: [width, height] })

  const sucursal = data.sucursal

  function hoja_1() {
    // monto en $$
    doc.text(format_money(data.monto_prenda), 45 + x_diff, 37 + y_diff)

    // lugar y fecha
    doc.setFontSize(8)
    doc.text(
      `${sucursal.provincia.toUpperCase()} ${moment(
        parseDate(data.fecha_prenda)
      )
        .locale('es')
        .format('DD [de] MMMM')
        .toUpperCase()}`,
      135 + x_diff,
      36 + y_diff
    )
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('YYYY'),
      195 + x_diff,
      36 + y_diff
    )

    // monto en letras
    const monto = salto_linea(
      `${NumeroALetra(data.monto_prenda).toUpperCase()}. -`,
      60
    )
    doc.text(monto.parte1, 105 + x_diff, 47 + y_diff)
    doc.text(monto.parte2, 30 + x_diff, 55 + y_diff)

    // titular y vehiculo
    doc.text(data.cliente.toUpperCase(), 65 + x_diff, 60 + y_diff)
    doc.text(
      `UN AUTOMOVIL ${data.tipo_id == 3 ? 'USADO' : '0KM'}, DOMINIO${
        data.tipo_id == 3 ? `: ${format_str(data.patente)}` : ' A PATENTAR'
      }, MCA: ${data.marca}, MODELO ${data.modelo}`,
      45 + x_diff,
      80 + y_diff
    )
    doc.text(
      `TIPO: ${data.tipo.toUpperCase()}, MAC MOTOR: ${
        data.marca_motor
      }, Nº MOTOR: ${data.motor},`,
      30 + x_diff,
      85 + y_diff
    )
    doc.text(
      `MCA CHASIS: ${data.marca_chasis}, Nº CASIS: ${
        data.chasis
      }, AÑO: ${format_str(data.anio)}, USO: ${format_str(
        data.uso
      ).toUpperCase()}`,
      30 + x_diff,
      90 + y_diff
    )

    // direccion
    doc.text(data.provincia.toUpperCase(), 105 + x_diff, 112 + y_diff)
    doc.text(
      format_str(data.departamento).toUpperCase(),
      30 + x_diff,
      117 + y_diff
    )
    doc.text(
      format_str(data.localidad).toUpperCase(),
      105 + x_diff,
      122 + y_diff
    )
    doc.text(format_str(data.calle).toUpperCase(), 115 + x_diff, 127 + y_diff)
    doc.text(format_str(data.numero).toUpperCase(), 180 + x_diff, 127 + y_diff)

    // interes
    doc.text(format_str(data.interes), 120 + x_diff, 168 + y_diff)

    // cliente
    doc.text(data.cliente.toUpperCase(), 150 + x_diff, 203 + y_diff)
    doc.text(
      format_str(data.estado_civil).toUpperCase(),
      142.5 + x_diff,
      207 + y_diff
    )
    doc.text(
      format_str(data.ocupacion).toUpperCase(),
      185 + x_diff,
      207 + y_diff
    )
    doc.text(
      format_str(data.nacionalidad).toUpperCase(),
      145 + x_diff,
      212 + y_diff
    )
    doc.text(
      moment(new Date()).diff(parseDate(data.nacimiento), 'years').toString(),
      190 + x_diff,
      212 + y_diff
    )
    doc.text(
      `${format_str(data.calle)} ${format_str(data.numero)} ${format_str(
        data.piso
      )} ${format_str(data.dep)} ${format_str(data.localidad)}`.toUpperCase(),
      135 + x_diff,
      217 + y_diff
    )
    doc.text(format_str(data.dni).toUpperCase(), 165 + x_diff, 222 + y_diff)

    // REVERSO DE LA PRIMER PAGINA
    doc.addPage()
    doc.text(format_str(data.cuotas), 30 + x_diff, 15 + y_diff)
    doc.text(
      format_money(data.valor_cuota).substring(
        2,
        format_money(data.valor_cuota).length
      ),
      65 + x_diff,
      15 + y_diff
    )
    doc.setFontSize(7)
    doc.text(
      NumeroALetra(data.valor_cuota).toUpperCase(),
      102.5 + x_diff,
      15 + y_diff
    )
    doc.setFontSize(8)
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('DD'),
      132.5 + x_diff,
      20 + y_diff
    )
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('MM'),
      142.5 + x_diff,
      20 + y_diff
    )
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('YY'),
      152.5 + x_diff,
      20 + y_diff
    )
  }

  // genera las 3 copias de la hoja 1
  hoja_1()
  doc.addPage()
  hoja_1()
  doc.addPage()
  hoja_1()

  const hoja1 = doc.output('arraybuffer')

  // SEGUNDA HOJA (santander)
  doc = new jsPDF({ putOnlyUsedFonts: false, format: [widthRio, heightRio] })

  function hoja_2() {
    y_diff = 0
    x_diff = 0
    doc.setFontSize(8)
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('DD'),
      115 + x_diff,
      42 + y_diff
    )
    doc.text(
      moment(parseDate(data.fecha_prenda)).locale('es').format('MMMM'),
      130 + x_diff,
      42 + y_diff
    )
    doc.text(
      moment(parseDate(data.fecha_prenda)).format('YYYY'),
      195 + x_diff,
      42 + y_diff
    )
    doc.text(data.cliente.toUpperCase(), 130 + x_diff, 47 + y_diff)

    // REVERSO DE LA SEGUNDA HOJA (en white)
    doc.addPage()
  }

  // genera las 3 copias de la hoja 2
  hoja_2()
  doc.addPage()
  hoja_2()
  doc.addPage()
  hoja_2()

  const hoja2 = doc.output('arraybuffer')

  // TERCER HOJA
  doc = new jsPDF({ putOnlyUsedFonts: false, format: [widthRio, heightRio] })

  function hoja_3() {
    y_diff = 0
    x_diff = 0

    // REVERSO DE LA TERCER HOJA
    doc.addPage()
    doc.setFontSize(8)
    doc.text(format_str(data.calle).toUpperCase(), 75 + x_diff, 60 + y_diff)
    doc.text(format_str(data.numero).toUpperCase(), 180 + x_diff, 60 + y_diff)
    doc.setFontSize(7)
    doc.text(
      format_str(data.localidad).toUpperCase(),
      77.5 + x_diff,
      64.5 + y_diff
    )
    doc.setFontSize(8)
    doc.text(data.provincia.toUpperCase(), 145 + x_diff, 65 + y_diff)
  }

  // genera las 3 copias de la hoja 3
  hoja_3()
  doc.addPage()
  hoja_3()
  doc.addPage()
  hoja_3()

  // devuelve los bytes del pdf generado
  return {
    hoja1: hoja1,
    hoja2: hoja2,
    hoja3: doc.output('arraybuffer')
  }
}

export async function get_file(url_logo) {
  // devuelve los bytes del pdf que esta almacenado en la api
  const fileBytes = await fetch(url_logo).then(res => res.arrayBuffer())
  return fileBytes
}

// devuelve un array buffer del pdf
export async function reciboEfDycarA4(data) {
  // obtiene el pdf que esta almacenado en la api
  const existingPdfBytes = await get_pdf(data.url_pdf)

  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

  const pages = pdfDoc.getPages()
  const firstPage = pages[0]

  // SET LOGO
  const jpgImageBytes = await get_file(data.url_logo)

  const jpgImage = await pdfDoc.embedJpg(jpgImageBytes)
  const jpgDims = jpgImage.scale(0.2)

  firstPage.drawImage(jpgImage, {
    x: 80,
    y: data.empresa == 15 ? 725 : 715,
    width: jpgDims.width,
    height: jpgDims.height
  })

  // SET DATA
  // numero
  firstPage.drawText(data.numero, {
    x: 372,
    y: 721,
    size: 13,
    font: helveticaBold
  })
  // fecha
  firstPage.drawText(data.fecha, {
    x: 390,
    y: 686,
    size: 10,
    font: helveticaFont
  })
  // vendedor
  if (data.vendedor) {
    const vendedor = salto_linea(data.vendedor, 14)
    firstPage.drawText(vendedor.parte1, {
      x: 405,
      y: 668,
      size: 10,
      font: helveticaFont
    })
    firstPage.drawText(vendedor.parte2, {
      x: 405,
      y: 653,
      size: 10,
      font: helveticaFont
    })
  }
  // razon social
  firstPage.drawText(data.razon_social.toString(), {
    x: 146,
    y: 686,
    size: 10,
    font: helveticaFont
  })
  // cuit
  firstPage.drawText(data.cuit.toString(), {
    x: 110,
    y: 668,
    size: 10,
    font: helveticaFont
  })
  // domicilio
  firstPage.drawText(data.domicilio.toString(), {
    x: 128,
    y: 651,
    size: 10,
    font: helveticaFont
  })
  // telefono
  firstPage.drawText(data.telefono.toString(), {
    x: 126,
    y: 634,
    size: 10,
    font: helveticaFont
  })
  // cliente
  firstPage.drawText(data.cliente, {
    x: 112,
    y: 561,
    size: 11,
    font: helveticaFont
  })
  // dni
  firstPage.drawText(data.documento, {
    x: 71,
    y: 540,
    size: 11,
    font: helveticaFont
  })
  // sol ad
  firstPage.drawText(data.preimpreso.toString(), {
    x: 135,
    y: 523,
    size: 11,
    font: helveticaFont
  })
  // monto en $
  firstPage.drawText(format_money(data.importe).toString(), {
    x: 380,
    y: 387,
    size: 11,
    font: helveticaFont
  })
  // monto en texto
  const importe_texto = salto_linea(NumerosALetras(data.importe), 40)
  firstPage.drawText(importe_texto.parte1, {
    x: 98,
    y: 359,
    size: 11,
    font: helveticaFont
  })
  firstPage.drawText(importe_texto.parte2, {
    x: 99,
    y: 342,
    size: 11,
    font: helveticaFont
  })
  // total
  firstPage.drawText(format_money(data.importe), {
    x: 460,
    y: 358,
    size: 11,
    font: helveticaBold
  })

  const pdfBytes = await pdfDoc.save()

  return pdfBytes.buffer
}

// pdf cobro online

export async function pdfCobroOnlineTermica(data) {
  try {
    // obtengo la primer plantilla
    const existingPdfBytes = await get_pdf_jarvis(
      'pdfCompElectronicoEpsonExitosoOriginal'
    )
    // obtengo la segunda plantilla
    const existingPdfBytess = await get_pdf_jarvis(
      'pdfCompElectronicoEpsonExitosoCopia'
    )
    // instancio dos documentos
    const pdfDoc1 = await PDFDocument.load(existingPdfBytes)
    const pdfDoc2 = await PDFDocument.load(existingPdfBytess)
    // obtengo mi documento principal
    const pdfDoc = await PDFDocument.create()
    const [docPage1] = await pdfDoc.copyPages(pdfDoc1, [0])
    const [docPage2] = await pdfDoc.copyPages(pdfDoc2, [0])
    // inserto las paginas en mi documento principal
    pdfDoc.addPage(docPage1)
    pdfDoc.insertPage(1, docPage2)
    // empiezo a escribir
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    for (let id = 0; id <= 1; id++) {
      const actualPage = pages[id]
      // fecha
      let fecha = ''
      let fechaComp = ''
      if (!isNaN(data.tdate)) {
        fecha = moment.unix(parseInt(data.tdate)).format('DD/MM/YYYY')
        fechaComp = moment.unix(parseInt(data.tdate))
      } else {
        fecha = moment(data.tdate.toString()).format('DD/MM/YYYY')
        fechaComp = moment(data.tdate.toString())
      }
      actualPage.drawText(fecha.toString(), {
        x: 25,
        y: 430,
        size: 7,
        font: helveticaFont
      })
      // marca
      actualPage.drawText(data.marca, {
        x: 80,
        y: 430,
        size: 7,
        font: helveticaFont
      })
      // hora
      actualPage.drawText(moment(fechaComp).format('HH:mm'), {
        x: 145,
        y: 430,
        size: 7,
        font: helveticaFont
      })
      // empresa
      actualPage.drawText(data.empresa_nombre, {
        x: 55,
        y: 410,
        size: 7,
        font: helveticaFont
      })
      // domicilio
      actualPage.drawText(data.empresa_domicilio, {
        x: 53,
        y: 402,
        size: 7,
        font: helveticaFont
      })
      // cuit
      actualPage.drawText(data.empresa_cuit, {
        x: 43,
        y: 390,
        size: 7,
        font: helveticaFont
      })
      // comercio
      let comercio = '-'
      if (
        data.comercio_id != null &&
        data.comercio_id != undefined &&
        data.comercio_id.toString().length > 0
      ) {
        comercio = data.comercio_id.toString()
      }
      actualPage.drawText(comercio, {
        x: 135,
        y: 390,
        size: 7,
        font: helveticaFont
      })
      // tarjeta
      actualPage.drawText(data.tarjeta_numero, {
        x: 90,
        y: 380,
        size: 7,
        font: helveticaFont
      })
      // titular
      actualPage.drawText(data.cliente_nombre, {
        x: 45,
        y: 370,
        size: 7,
        font: helveticaFont
      })
      // telefono
      let telefono = '-'
      if (
        data.cliente_telefono != null &&
        data.cliente_telefono != undefined &&
        data.cliente_telefono.toString().length > 0
      ) {
        telefono = data.cliente_telefono.toString()
      }
      actualPage.drawText(telefono, {
        x: 53,
        y: 360,
        size: 7,
        font: helveticaFont
      })
      // codigo autorizacion
      actualPage.drawText(data.codigo_autorizacion, {
        x: 72,
        y: 349,
        size: 7,
        font: helveticaFont
      })
      // transaccion
      actualPage.drawText(data.transaccion_id, {
        x: 61,
        y: 338,
        size: 7,
        font: helveticaFont
      })
      // numero
      actualPage.drawText(data.numero, {
        x: 50,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // lote
      let lote = ''
      if (
        data.lote != null &&
        data.lote != undefined &&
        data.lote.toString().length > 0
      )
        lote = data.lote
      actualPage.drawText(lote, {
        x: 95,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // cuotas
      actualPage.drawText(data.cuotas.toString(), {
        x: 137,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // solicitud de adhesion
      if (
        data.solicitud_adhesion != null &&
        data.solicitud_adhesion != undefined &&
        data.solicitud_adhesion.toString().length > 0
      ) {
        actualPage.drawText(
          'Solicitud Adhesion: ' + data.solicitud_adhesion.toString(),
          {
            x: 26,
            y: 317,
            size: 7,
            font: helveticaFont
          }
        )
      }
      // total
      actualPage.drawText(convertDecimals(data.importe).toString(), {
        x: 73,
        y: 304,
        size: 10,
        font: helveticaFont
      })
      // original
      if (id == 0) {
        actualPage.drawText('ORIGINAL COMERCIO', {
          x: 62,
          y: 240,
          size: 8,
          font: helveticaFont
        })
      }
    }
    let pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  } catch (error) {
    return {
      resultado: 0,
      msj:
        'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineTermica: ' +
        error.message,
      pdf: null
    }
  }
}

export async function pdfCobroOnlineTermicaAnulacion(data) {
  try {
    // obtengo la primer plantilla
    const existingPdfBytes = await get_pdf_jarvis(
      'pdfCompElectronicoEpsonAnulacionOriginal'
    )
    // obtengo la segunda plantilla
    const existingPdfBytess = await get_pdf_jarvis(
      'pdfCompElectronicoEpsonAnulacionCopia'
    )
    // instancio dos documentos
    const pdfDoc1 = await PDFDocument.load(existingPdfBytes)
    const pdfDoc2 = await PDFDocument.load(existingPdfBytess)
    // obtengo mi documento principal
    const pdfDoc = await PDFDocument.create()
    const [docPage1] = await pdfDoc.copyPages(pdfDoc1, [0])
    const [docPage2] = await pdfDoc.copyPages(pdfDoc2, [0])
    // inserto las paginas en mi documento principal
    pdfDoc.addPage(docPage1)
    pdfDoc.insertPage(1, docPage2)
    // empiezo a escribir
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    for (let id = 0; id <= 1; id++) {
      const actualPage = pages[id]
      // fecha
      let fecha = ''
      let fechaComp = ''
      if (!isNaN(data.tdate)) {
        fecha = moment.unix(parseInt(data.tdate)).format('DD/MM/YYYY')
        fechaComp = moment.unix(parseInt(data.tdate))
      } else {
        fecha = moment(data.tdate.toString()).format('DD/MM/YYYY')
        fechaComp = moment(data.tdate.toString())
      }
      actualPage.drawText(fecha.toString(), {
        x: 25,
        y: 430,
        size: 7,
        font: helveticaFont
      })
      // marca
      actualPage.drawText(data.marca, {
        x: 80,
        y: 430,
        size: 7,
        font: helveticaFont
      })
      // hora
      actualPage.drawText(moment(fechaComp).format('HH:mm'), {
        x: 145,
        y: 430,
        size: 7,
        font: helveticaFont
      })
      // empresa
      actualPage.drawText(data.empresa_nombre, {
        x: 55,
        y: 410,
        size: 7,
        font: helveticaFont
      })
      // domicilio
      actualPage.drawText(data.empresa_domicilio, {
        x: 53,
        y: 402,
        size: 7,
        font: helveticaFont
      })
      // cuit
      actualPage.drawText(data.empresa_cuit, {
        x: 43,
        y: 390,
        size: 7,
        font: helveticaFont
      })
      // comercio
      let comercio = '-'
      if (
        data.comercio_id != null &&
        data.comercio_id != undefined &&
        data.comercio_id.toString().length > 0
      ) {
        comercio = data.comercio_id.toString()
      }
      actualPage.drawText(comercio, {
        x: 135,
        y: 390,
        size: 7,
        font: helveticaFont
      })
      // tarjeta
      actualPage.drawText(data.tarjeta_numero, {
        x: 90,
        y: 380,
        size: 7,
        font: helveticaFont
      })
      // titular
      actualPage.drawText(data.cliente_nombre, {
        x: 45,
        y: 370,
        size: 7,
        font: helveticaFont
      })
      // telefono
      let telefono = '-'
      if (
        data.cliente_telefono != null &&
        data.cliente_telefono != undefined &&
        data.cliente_telefono.toString().length > 0
      ) {
        telefono = data.cliente_telefono.toString()
      }
      actualPage.drawText(telefono, {
        x: 53,
        y: 360,
        size: 7,
        font: helveticaFont
      })
      // codigo autorizacion
      actualPage.drawText(data.codigo_autorizacion, {
        x: 72,
        y: 349,
        size: 7,
        font: helveticaFont
      })
      // transaccion
      actualPage.drawText(data.transaccion_id, {
        x: 61,
        y: 338,
        size: 7,
        font: helveticaFont
      })
      // numero
      actualPage.drawText(data.numero, {
        x: 50,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // lote
      let lote = ''
      if (
        data.lote != null &&
        data.lote != undefined &&
        data.lote.toString().length > 0
      )
        lote = data.lote
      actualPage.drawText(lote, {
        x: 95,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // cuotas
      actualPage.drawText(data.cuotas.toString(), {
        x: 137,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // solicitud de adhesion
      if (
        data.solicitud_adhesion != null &&
        data.solicitud_adhesion != undefined &&
        data.solicitud_adhesion.toString().length > 0
      ) {
        actualPage.drawText(
          'Solicitud Adhesion: ' + data.solicitud_adhesion.toString(),
          {
            x: 26,
            y: 317,
            size: 7,
            font: helveticaFont
          }
        )
      }
      // total
      actualPage.drawText(convertDecimals(data.importe).toString(), {
        x: 73,
        y: 304,
        size: 10,
        font: helveticaFont
      })
      // original
      if (id == 0) {
        actualPage.drawText('ORIGINAL COMERCIO', {
          x: 62,
          y: 240,
          size: 8,
          font: helveticaFont
        })
      }
    }
    let pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  } catch (error) {
    return {
      resultado: 0,
      msj:
        'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineTermicaAnulacion: ' +
        error.message,
      pdf: null
    }
  }
}

export async function pdfCobroOnlineA4(data) {
  try {
    // obtengo la plantilla
    const existingPdfBytes = await get_pdf_jarvis('pdfComprobanteElectronicoA4')
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    // empiezo a escribir
    // fecha
    let fecha = ''
    let fechaComp = ''
    if (!isNaN(data.tdate)) {
      fecha = moment.unix(parseInt(data.tdate)).format('DD/MM/YYYY')
      fechaComp = moment.unix(parseInt(data.tdate))
    } else {
      fecha = moment(data.tdate.toString()).format('DD/MM/YYYY')
      fechaComp = moment(data.tdate.toString())
    }
    firstPage.drawText(fecha.toString(), {
      x: 50,
      y: 799,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(fecha.toString(), {
      x: 260,
      y: 799,
      size: 7,
      font: helveticaFont
    })
    // hora
    firstPage.drawText(moment(fechaComp).format('HH:mm'), {
      x: 110,
      y: 799,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(moment(fechaComp).format('HH:mm'), {
      x: 320,
      y: 799,
      size: 7,
      font: helveticaFont
    })
    // empresa
    firstPage.drawText(data.empresa_nombre, {
      x: 30,
      y: 765,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.empresa_nombre, {
      x: 240,
      y: 765,
      size: 7,
      font: helveticaFont
    })
    // direccion
    firstPage.drawText(data.empresa_domicilio, {
      x: 55,
      y: 752,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.empresa_domicilio, {
      x: 263,
      y: 752,
      size: 7,
      font: helveticaFont
    })
    // cuit
    firstPage.drawText(data.empresa_cuit, {
      x: 42,
      y: 736,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.empresa_cuit, {
      x: 250,
      y: 736,
      size: 7,
      font: helveticaFont
    })
    // comercio
    let comercio = '-'
    if (
      data.comercio_id != null &&
      data.comercio_id != undefined &&
      data.comercio_id.toString().length > 0
    ) {
      comercio = data.comercio_id.toString()
    }
    firstPage.drawText(comercio, {
      x: 54,
      y: 720.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(comercio, {
      x: 262,
      y: 720.5,
      size: 7,
      font: helveticaFont
    })
    // tarjeta
    firstPage.drawText(data.tarjeta_numero, {
      x: 92,
      y: 705,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.tarjeta_numero, {
      x: 302,
      y: 705,
      size: 7,
      font: helveticaFont
    })
    // titular
    firstPage.drawText(data.cliente_nombre, {
      x: 45,
      y: 689,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.cliente_nombre, {
      x: 254,
      y: 689,
      size: 7,
      font: helveticaFont
    })
    // telefono
    let telefono = '-'
    if (
      data.cliente_telefono != null &&
      data.cliente_telefono != undefined &&
      data.cliente_telefono.toString().length > 0
    ) {
      telefono = data.cliente_telefono.toString()
    }
    firstPage.drawText(telefono, {
      x: 52,
      y: 674,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(telefono, {
      x: 260,
      y: 674,
      size: 7,
      font: helveticaFont
    })
    // codigo autorizacion
    firstPage.drawText(data.codigo_autorizacion, {
      x: 71,
      y: 658,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.codigo_autorizacion, {
      x: 280,
      y: 658,
      size: 7,
      font: helveticaFont
    })
    // numero
    firstPage.drawText(data.numero, {
      x: 50,
      y: 641.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.numero, {
      x: 260,
      y: 641.5,
      size: 7,
      font: helveticaFont
    })
    // lote
    let lote = ''
    if (
      data.lote != null &&
      data.lote != undefined &&
      data.lote.toString().length > 0
    )
      lote = data.lote
    firstPage.drawText(lote, {
      x: 105,
      y: 641.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(lote, {
      x: 314,
      y: 641.5,
      size: 7,
      font: helveticaFont
    })
    // total
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 60,
      y: 625.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 269,
      y: 625.5,
      size: 7,
      font: helveticaFont
    })
    // cuotas
    firstPage.drawText(data.cuotas.toString(), {
      x: 113,
      y: 625.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.cuotas.toString(), {
      x: 322,
      y: 625.5,
      size: 7,
      font: helveticaFont
    })
    // transaccion
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 61,
      y: 608.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 272,
      y: 608.5,
      size: 7,
      font: helveticaFont
    })
    // marca
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 24,
      y: 593,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 234,
      y: 593,
      size: 7,
      font: helveticaFont
    })
    // solicitud de adhesion
    if (
      data.solicitud_adhesion != null &&
      data.solicitud_adhesion != undefined &&
      data.solicitud_adhesion.toString().length > 0
    ) {
      firstPage.drawText(
        'Solicitud Adhesion: ' + data.solicitud_adhesion.toString(),
        {
          x: 24,
          y: 580,
          size: 7,
          font: helveticaFont
        }
      )
      firstPage.drawText(
        'Solicitud Adhesion: ' + data.solicitud_adhesion.toString(),
        {
          x: 234,
          y: 580,
          size: 7,
          font: helveticaFont
        }
      )
    }
    // respuesta
    const pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  } catch (error) {
    return {
      resultado: 0,
      msj:
        'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineA4: ' +
        error.message,
      pdf: null
    }
  }
}

export async function pdfCobroOnlineA4Anulacion(data) {
  try {
    // obtengo la plantilla
    const existingPdfBytes = await get_pdf_jarvis(
      'pdfCompElectronicoAnulacionA4'
    )
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    // empiezo a escribir
    // fecha
    let fecha = ''
    let fechaComp = ''
    if (!isNaN(data.tdate)) {
      fecha = moment.unix(parseInt(data.tdate)).format('DD/MM/YYYY')
      fechaComp = moment.unix(parseInt(data.tdate))
    } else {
      fecha = moment(data.tdate.toString()).format('DD/MM/YYYY')
      fechaComp = moment(data.tdate.toString())
    }
    firstPage.drawText(fecha.toString(), {
      x: 50,
      y: 799,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(fecha.toString(), {
      x: 260,
      y: 799,
      size: 7,
      font: helveticaFont
    })
    // hora
    firstPage.drawText(moment(fechaComp).format('HH:mm'), {
      x: 110,
      y: 799,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(moment(fechaComp).format('HH:mm'), {
      x: 320,
      y: 799,
      size: 7,
      font: helveticaFont
    })
    // empresa
    firstPage.drawText(data.empresa_nombre, {
      x: 30,
      y: 765,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.empresa_nombre, {
      x: 240,
      y: 765,
      size: 7,
      font: helveticaFont
    })
    // direccion
    firstPage.drawText(data.empresa_domicilio, {
      x: 55,
      y: 752,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.empresa_domicilio, {
      x: 263,
      y: 752,
      size: 7,
      font: helveticaFont
    })
    // cuit
    firstPage.drawText(data.empresa_cuit, {
      x: 42,
      y: 736,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.empresa_cuit, {
      x: 250,
      y: 736,
      size: 7,
      font: helveticaFont
    })
    // comercio
    let comercio = '-'
    if (
      data.comercio_id != null &&
      data.comercio_id != undefined &&
      data.comercio_id.toString().length > 0
    ) {
      comercio = data.comercio_id.toString()
    }
    firstPage.drawText(comercio, {
      x: 54,
      y: 720.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(comercio, {
      x: 262,
      y: 720.5,
      size: 7,
      font: helveticaFont
    })
    // tarjeta
    firstPage.drawText(data.tarjeta_numero, {
      x: 92,
      y: 705,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.tarjeta_numero, {
      x: 302,
      y: 705,
      size: 7,
      font: helveticaFont
    })
    // titular
    firstPage.drawText(data.cliente_nombre, {
      x: 45,
      y: 689,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.cliente_nombre, {
      x: 254,
      y: 689,
      size: 7,
      font: helveticaFont
    })
    // telefono
    let telefono = '-'
    if (
      data.cliente_telefono != null &&
      data.cliente_telefono != undefined &&
      data.cliente_telefono.toString().length > 0
    ) {
      telefono = data.cliente_telefono.toString()
    }
    firstPage.drawText(telefono, {
      x: 52,
      y: 674,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(telefono, {
      x: 260,
      y: 674,
      size: 7,
      font: helveticaFont
    })
    // codigo autorizacion
    firstPage.drawText(data.codigo_autorizacion, {
      x: 71,
      y: 658,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.codigo_autorizacion, {
      x: 280,
      y: 658,
      size: 7,
      font: helveticaFont
    })
    // numero
    firstPage.drawText(data.numero, {
      x: 50,
      y: 641.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.numero, {
      x: 260,
      y: 641.5,
      size: 7,
      font: helveticaFont
    })
    // lote
    let lote = ''
    if (
      data.lote != null &&
      data.lote != undefined &&
      data.lote.toString().length > 0
    )
      lote = data.lote
    firstPage.drawText(lote, {
      x: 105,
      y: 641.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(lote, {
      x: 314,
      y: 641.5,
      size: 7,
      font: helveticaFont
    })
    // total
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 60,
      y: 625.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 269,
      y: 625.5,
      size: 7,
      font: helveticaFont
    })
    // cuotas
    firstPage.drawText(data.cuotas.toString(), {
      x: 113,
      y: 625.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.cuotas.toString(), {
      x: 322,
      y: 625.5,
      size: 7,
      font: helveticaFont
    })
    // transaccion
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 61,
      y: 608.5,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 272,
      y: 608.5,
      size: 7,
      font: helveticaFont
    })
    // marca
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 24,
      y: 593,
      size: 7,
      font: helveticaFont
    })
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 234,
      y: 593,
      size: 7,
      font: helveticaFont
    })
    // solicitud de adhesion
    if (
      data.solicitud_adhesion != null &&
      data.solicitud_adhesion != undefined &&
      data.solicitud_adhesion.toString().length > 0
    ) {
      firstPage.drawText(
        'Solicitud Adhesion: ' + data.solicitud_adhesion.toString(),
        {
          x: 24,
          y: 580,
          size: 7,
          font: helveticaFont
        }
      )
      firstPage.drawText(
        'Solicitud Adhesion: ' + data.solicitud_adhesion.toString(),
        {
          x: 234,
          y: 580,
          size: 7,
          font: helveticaFont
        }
      )
    }
    // respuesta
    const pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  } catch (error) {
    return {
      resultado: 0,
      msj:
        'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineA4Anulacion: ' +
        error.message,
      pdf: null
    }
  }
}

export async function pdfCobroOnlineReciboEuDycarA4(data) {
  try {
    let existingPdfBytes1 = await pdfCobroOnlineA4(data)
    if (existingPdfBytes1.pdf) {
      existingPdfBytes1 = existingPdfBytes1.pdf
    }
    const existingPdfBytes2 = await get_pdf_jarvis(
      'recibo-cobronline-eurodycar'
    )

    // instancio dos documentos
    const pdfDoc1 = await PDFDocument.load(existingPdfBytes1)
    const pdfDoc2 = await PDFDocument.load(existingPdfBytes2)
    // obtengo mi documento principal
    const pdfDoc = await PDFDocument.create()

    const [docPage1] = await pdfDoc.copyPages(pdfDoc1, [0])
    const [docPage2] = await pdfDoc.copyPages(pdfDoc2, [0])

    // inserto las paginas en mi documento principal
    pdfDoc.addPage(docPage1)
    pdfDoc.insertPage(1, docPage2)

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

    const pages = pdfDoc.getPages()

    const secondPage = pages[1]

    // fecha
    let fecha = ''
    let fechaComp = ''
    if (!isNaN(data.tdate)) {
      fecha = moment.unix(parseInt(data.tdate)).format('DD/MM/YYYY')
      fechaComp = moment.unix(parseInt(data.tdate))
    } else {
      fecha = moment(data.tdate.toString()).format('DD/MM/YYYY')
      fechaComp = moment(data.tdate.toString())
    }
    // Fecha
    secondPage.drawText(fecha.toString(), {
      x: 390,
      y: 687,
      size: 10,
      font: helveticaFont
    })

    // Num recibo
    let numeroRecibo = data.pedido_numero.toString()
    if (numeroRecibo.length < 8) {
      numeroRecibo = numeroRecibo.padStart(8, '0')
    } else {
      numeroRecibo = numeroRecibo.slice(0, 8)
    }
    secondPage.drawText(numeroRecibo, {
      x: 414,
      y: 722,
      size: 14,
      font: helveticaBold
    })

    // cliente
    secondPage.drawText(data.cliente_nombre, {
      x: 112,
      y: 562,
      size: 11,
      font: helveticaFont
    })
    // dni
    secondPage.drawText(format_str(data.cliente_documento), {
      x: 95,
      y: 538,
      size: 11,
      font: helveticaFont
    })
    //
    // suscripción
    secondPage.drawText(format_str(data.solicitud_adhesion), {
      x: 135,
      y: 514,
      size: 11,
      font: helveticaFont
    })

    // Tarjeta
    secondPage.drawText(`TARJETA - ${data.marca}`, {
      x: 135,
      y: 387,
      size: 11,
      font: helveticaFont
    })

    // monto en $
    secondPage.drawText(format_money(data.importe).toString(), {
      x: 390,
      y: 387,
      size: 11,
      font: helveticaFont
    })
    // monto en texto
    const importe_texto = salto_linea(NumerosALetras(data.importe), 40)
    secondPage.drawText(importe_texto.parte1, {
      x: 98,
      y: 359,
      size: 11,
      font: helveticaFont
    })
    secondPage.drawText(importe_texto.parte2, {
      x: 99,
      y: 342,
      size: 11,
      font: helveticaFont
    })

    // Total
    secondPage.drawText(format_money(data.importe).toString(), {
      x: 459,
      y: 359,
      size: 11,
      font: helveticaBold
    })

    const pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  } catch (error) {
    return {
      resultado: 0,
      msj:
        'Ocurrio un problema al ejecutar el metodo pdfCobroOnline: ' +
        error.message,
      pdf: null
    }
  }
}

export async function pdfCobroOnline(data) {
  try {
    // debo obtener la plantilla de la termica o de A4?
    let pdfPeticion = {}

    if (data.impresora_termica == 1) {
      // es anulacion o cobro exitoso?
      if (data.estado == 2 || data.estado == 8 || data.estado == 9) {
        // cobro exitoso
        pdfPeticion = await pdfCobroOnlineTermica(data)
      } else {
        // anulacion
        pdfPeticion = await pdfCobroOnlineTermicaAnulacion(data)
      }
    } else {
      // es anulacion o cobro exitoso?
      if (data.estado == 2 || data.estado == 8 || data.estado == 9) {
        // cobro exitoso
        pdfPeticion = await pdfCobroOnlineA4(data)

        if (data.empresa_codigo == 15) {
          pdfPeticion = await pdfCobroOnlineReciboEuDycarA4(data)
        }
      } else {
        // anulacion
        pdfPeticion = await pdfCobroOnlineA4Anulacion(data)
      }
    }
    return pdfPeticion
  } catch (error) {
    return {
      resultado: 0,
      msj:
        'Ocurrio un problema al ejecutar el metodo pdfCobroOnline: ' +
        error.message,
      pdf: null
    }
  }
}

export async function pdfVoucherCombustible(
  urlPDF,
  urlMarca,
  urlEmpresa,
  urlEstacion,
  importe,
  numero,
  direccion,
  patente,
  vin,
  fecha,
  fecha_vencimiento = null
) {
  const pdfBytes = await get_pdf(urlPDF)
  const pdfDoc = await PDFDocument.load(pdfBytes)

  const marcaImageBytes = await loadImage(urlMarca)
  const empresaImageBytes = await loadImage(urlEmpresa)
  const estacionImageBytes = await loadImage(urlEstacion)

  try {
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const helveticaFontBold = await pdfDoc.embedFont(
      StandardFonts.HelveticaBold
    )

    const marcaImage = await pdfDoc.embedPng(marcaImageBytes)
    const empresaImage = await pdfDoc.embedPng(empresaImageBytes)
    const estacionImage = await pdfDoc.embedPng(estacionImageBytes)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    const { width, height } = firstPage.getSize()

    const marcaImageDims = marcaImage.scale(1) // Escala al 100%
    const marcaAspectRatio = marcaImageDims.width / marcaImageDims.height

    const newWidthMarca = 120
    const newHeight = newWidthMarca / marcaAspectRatio

    firstPage.drawImage(marcaImage, {
      x: 1455,
      y: height - 90,
      width: newWidthMarca,
      height: newHeight
    })

    // SI LA FAMILIA ES IGUAL A 1
    const empresaImageDims = empresaImage.scale(1)
    const empresaAspectRatio = empresaImageDims.width / empresaImageDims.height
    const newWidthEmpresa = 160
    const newEmpresaHeight = newWidthEmpresa / empresaAspectRatio

    firstPage.drawImage(empresaImage, {
      x: 412,
      y: height - 85,
      width: newWidthEmpresa,
      height: newEmpresaHeight
    })
    // IMAGEN FAMILIA TROQUEL
    const newWidthEmpresaTroquel = 170
    const newEmpresaHeightTroquel = newWidthEmpresa / empresaAspectRatio

    firstPage.drawImage(empresaImage, {
      x: 110,
      y: height - 690,
      width: newWidthEmpresaTroquel,
      height: newEmpresaHeightTroquel
    })

    const estacionImageDims = estacionImage.scale(1)
    const estacionAspectRatio =
      estacionImageDims.width / estacionImageDims.height
    const newWidthEstacion = 235
    const newEstacionHeight = newWidthEstacion / estacionAspectRatio

    firstPage.drawImage(estacionImage, {
      x: 1400,
      y: height - 710,
      width: newWidthEstacion,
      height: newEstacionHeight
    })

    // TEXTOS

    // Nro recibo
    firstPage.drawText(numero, {
      x: 90,
      y: height - 173,
      size: 24,
      font: helveticaFont
    })
    firstPage.drawText(numero, {
      x: width - 295,
      y: height - 173,
      size: 24,
      font: helveticaFont
    })

    // IMPORTE
    firstPage.drawText(importe, {
      x: 505,
      y: 285,
      size: 220,
      font: helveticaFontBold,
      color: rgb(1, 1, 1)
    })

    firstPage.drawText(importe, {
      x: 910,
      y: 92,
      size: 24,
      font: helveticaFontBold
    })

    // DIRECCIÓN
    firstPage.drawText(direccion, {
      x: 680,
      y: 62,
      size: 20,
      font: helveticaFont
    })

    if (patente) {
      if (typeof patente === 'string' && patente.length > 14) {
        const part1 = patente.slice(0, 14)
        const part2 = patente.slice(14)

        firstPage.drawText(part1, {
          x: 131,
          y: height - 290,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })

        firstPage.drawText(part2, {
          x: 131,
          y: height - 320, 
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })

        firstPage.drawText(part1, {
          x: width - 250,
          y: height - 290,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })

        firstPage.drawText(part2, {
          x: width - 250,
          y: height - 320, 
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })
      } else {
        firstPage.drawText(patente, {
          x: 131,
          y: height - 320,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })

        firstPage.drawText(patente, {
          x: width - 250,
          y: height - 320,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })
      }
    }

    if (vin && !patente) {
      if (typeof vin === 'string' && vin.length > 14) {
        const part1 = vin.slice(0, 14)
        const part2 = vin.slice(14)

        firstPage.drawText(part1, {
          x: 100,
          y: height - 290,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })

        firstPage.drawText(part2, {
          x: 100,
          y: height - 320,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })

        firstPage.drawText(part1, {
          x: width - 275,
          y: height - 290,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })

        firstPage.drawText(part2, {
          x: width - 275,
          y: height - 320,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })
      } else {
        firstPage.drawText(vin, {
          x: 100,
          y: height - 320,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })

        firstPage.drawText(vin, {
          x: width - 275,
          y: height - 320,
          size: 26,
          font: helveticaFont,
          color: rgb(37 / 255, 37 / 255, 37 / 255)
        })
      }
    }

    if (fecha) {
      firstPage.drawText(fecha, {
        x: 131,
        y: height - 438,
        size: 26,
        font: helveticaFont,
        color: rgb(37 / 255, 37 / 255, 37 / 255)
      })

      firstPage.drawText(fecha, {
        x: width - 252,
        y: height - 438,
        size: 26,
        font: helveticaFont,
        color: rgb(37 / 255, 37 / 255, 37 / 255)
      })
    }

    if (fecha_vencimiento) {
      firstPage.drawText(fecha_vencimiento, {
        x: 740,
        y: height - 740,
        size: 19,
        font: helveticaFont,
        color: rgb(60 / 255, 60 / 255, 60 / 255) 
      })
    }

    const modifiedPdfBytes = await pdfDoc.save()

    return modifiedPdfBytes
  } catch (error) {
    return {
      exito: false,
      msj:
        'Ocurrio un problema al ejecutar el metodo vouchersCombustible: ' +
        error.message,
      pdf: null
    }
  }
}

export async function pdfOrdenDeCompra(
  urlPDF,
  fecha,
  nroOrden,
  retira,
  infoOrden,
  infoDetalle
) {
  const pdfBytes = await get_pdf(urlPDF)
  const pdfDoc = await PDFDocument.load(pdfBytes)

  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

  const pages = pdfDoc.getPages()
  const firstPage = pages[0]

  const { width, height } = firstPage.getSize()
  const textColor = rgb(37 / 255, 37 / 255, 37 / 255)

  try {
    if (infoOrden && infoOrden.familia_id === 1) {
      const empresaImageBytes = await loadImage(infoOrden.logo_familia)
      const marcaImageBytes = await loadImage(infoOrden.logo_empresa)

      const marcaImage = await pdfDoc.embedPng(marcaImageBytes)
      const empresaImage = await pdfDoc.embedPng(empresaImageBytes)

      // Obtengo las dimensiones originales de la imagen
      const marcaImageDims = marcaImage.scale(1) // Escala al 100%
      const marcaAspectRatio = marcaImageDims.width / marcaImageDims.height

      const newWidthMarca = 53
      const newHeight = newWidthMarca / marcaAspectRatio

      firstPage.drawImage(marcaImage, {
        x: 216,
        y: height - 58,
        width: newWidthMarca,
        height: newHeight
      })

      const empresaImageDims = empresaImage.scale(1)
      const empresaAspectRatio =
        empresaImageDims.width / empresaImageDims.height
      const newWidthEmpresa = 150
      const newEmpresaHeight = newWidthEmpresa / empresaAspectRatio

      firstPage.drawImage(empresaImage, {
        x: 14,
        y: height - 50,
        width: newWidthEmpresa,
        height: newEmpresaHeight
      })
    }

    // NRO ORDEN
    firstPage.drawText(nroOrden, {
      x: 325,
      y: height - 30,
      size: 12,
      font: helveticaFont,
      color: textColor
    })

    // RAZON SOCIAL
    if (infoOrden.razon_social) {
      firstPage.drawText(infoOrden.razon_social, {
        x: 14,
        y: height - 65,
        size: 12,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoOrden.cuit) {
      firstPage.drawText(infoOrden.cuit, {
        x: width - 167,
        y: height - 83,
        size: 7,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoOrden.ingresos_brutos) {
      firstPage.drawText(infoOrden.ingresos_brutos, {
        x: width - 136,
        y: height - 93,
        size: 7,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoOrden.inicio_actividad) {
      firstPage.drawText(infoOrden.inicio_actividad, {
        x: width - 160,
        y: height - 103,
        size: 7,
        font: helveticaFont,
        color: textColor
      })
    }

    // FECHA
    firstPage.drawText(fecha, {
      x: 345,
      y: height - 52,
      size: 8,
      font: helveticaFont,
      color: textColor
    })

    // RETIRA
    if (retira) {
      firstPage.drawText(retira, {
        x: 123,
        y: height - 210,
        size: 12,
        font: helveticaFont,
        color: textColor
      })
    }

    // DIRECCION
    if (infoOrden.direccion_sucursal) {
      firstPage.drawText(infoOrden.direccion_sucursal, {
        x: 65,
        y: height - 83,
        size: 8,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoOrden.telefono) {
      firstPage.drawText(infoOrden.telefono, {
        x: 33,
        y: height - 94,
        size: 8,
        font: helveticaFont,
        color: textColor
      })
    }

    // DETALLES
    if (infoDetalle.litros) {
      if (infoDetalle.litros != 'Tanque lleno') {
        firstPage.drawText(infoDetalle.litros, {
          x: 20,
          y: height - 255,
          size: 8,
          font: helveticaFont,
          color: textColor
        })
      } else {
        firstPage.drawText(infoDetalle.litros, {
          x: 71,
          y: height - 271,
          size: 8,
          font: helveticaFont,
          color: textColor
        })
      }
    }

    // SECTOR O DESTINO
    if (infoDetalle.sector) {
      firstPage.drawText(infoDetalle.sector, {
        x: 71,
        y: height - 255,
        size: 8,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoDetalle.destino) {
      firstPage.drawText(infoDetalle.destino, {
        x: 60,
        y: height - 149,
        size: 12,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoDetalle.vin) {
      firstPage.drawText(infoDetalle.vin, {
        x: 71,
        y: height - 305,
        size: 8,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoDetalle.dominio) {
      firstPage.drawText(infoDetalle.dominio, {
        x: 71,
        y: height - 322,
        size: 8,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoDetalle.tipo_combustible) {
      firstPage.drawText(infoDetalle.tipo_combustible, {
        x: 71,
        y: height - 339,
        size: 8,
        font: helveticaFont,
        color: textColor
      })
    }

    if (infoDetalle.observaciones) {
      firstPage.drawText(infoDetalle.observaciones, {
        x: 71,
        y: height - 357,
        size: 8,
        font: helveticaFont,
        color: textColor
      })
    }

    const modifiedPdfBytes = await pdfDoc.save()

    return modifiedPdfBytes
  } catch (error) {
    return {
      exito: false,
      message: error
    }
  }
}
