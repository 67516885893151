<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn style="position: absolute; top: -15px; right: 55px; z-index: 1" color="success" small fab
              title="Nueva clasificación" @click="abrirModalNuevaClasificacion">
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete v-model="filtro.empresa" item-text="nombre" item-value="id" hide-details outlined
                      dense clearable :items="familias" @change="getClasificaciones"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" xl="2" class="py-1">
                    Cuenta IIBB
                    <v-text-field v-model.trim="filtro.cuenta" hide-details outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" xl="3" class="py-1">
                    Código
                    <v-text-field v-model.trim="filtro.codigo" hide-details outlined dense type="number"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Clasificación IVA
                    <v-autocomplete v-model="filtro.clasificacion" item-text="Clasif_IVA" item-value="Numero"
                      hide-details outlined dense :items="clasificaciones" clearable></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Clasificación IIBB
                    <v-autocomplete v-model="filtro.clasificacion" item-text="Clasif" item-value="Numero" hide-details
                      outlined dense :items="clasificaciones" clearable></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="12" class="pt-0" style="">
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="mt-2">
          <v-data-table class="cebra elevation-0 mt-2" item-key="id" sort-by="id" :headers="headers"
            :items="clasificacionesCompras" :loading="load" sort-desc dense :search="search">
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3" xl="3">
                  <SearchDataTable v-model="search" />
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn class="" small icon color="warning" @click="abrirModalEditarClasificacion(item)" title="Editar">
                <v-icon class="" small>fas fa-pen</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.Clasif_IIBB`]="{ item }">
              <span style="font-size: 11px;">
                {{ item.Clasif_IIBB }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <EditarClasificacionVentas v-model="modalEditarClasificacion" :clasificacion="clasificacionSeleccionada"
      @actualizar="actulizarEdicionClasif($event)" />
    <NuevaClasificacionVentas v-model="modalNuevaClasificacion" @actualizar="actulizarNuevaClasif($event)" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BtnFiltro from '../../../components/util/BtnFiltro.vue';
import EditarClasificacionVentas from '../../../components/admin/clasificacion/EditarClasificacionVentas.vue';
import NuevaClasificacionVentas from '../../../components/admin/clasificacion/NuevaClasificacionVentas.vue'
import SearchDataTable from '../../../components/util/SearchDataTable.vue';

export default {
  data() {
    return {
      panel: 0,
      load: false,
      search: '',
      modalNuevaClasificacion: false,
      modalEditarClasificacion: false,

      filtro: {
        cuenta: '',
        codigo: null,
        clasificacion: null,
        empresa: null
      },

      headers: [
        { text: 'Código', value: 'PLANNRO' },
        { text: 'Cuenta IIBB', value: 'Cuenta' },
        { text: 'Clasificación IIBB', value: 'Clasif_IIBB' },
        { text: 'Clasificación IVA', value: 'Clasif_IVA' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false
        }
      ],

      clasificaciones: [],
      clasificacionesCompras: [],
      clasificacionSeleccionada: null
    };
  },
  methods: {
    async buscar() {
      if (!this.filtro.empresa) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar una empresa.',
          color: 'warning'
        })
        return
      }
      await this.$store.dispatch('clasificacionVentas/getVentasClasif', {
        numero: this.filtro.clasificacion,
        empresa: this.filtro.empresa,
        cuenta: this.filtro.cuenta,
        codigo: this.filtro.codigo
      }).then(res => {
        if (res.exito) {
          this.clasificacionesCompras = res.data
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })
    },

    async getClasificaciones() {
      this.$store.state.loading = true
      await this.$store.dispatch('clasificacionVentas/getClasificaciones', {
        empresa: this.filtro.empresa
      }).then(res => {
        if (res.exito) {
          this.clasificaciones = res.data
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })
      this.$store.state.loading = false
    },

    async limpiarFiltro() {
      this.filtro = {
        cuenta: '',
        codigo: null,
        clasificacion: null,
        empresa: null
      }
    },

    abrirModalNuevaClasificacion() {
      this.modalNuevaClasificacion = true
    },

    abrirModalEditarClasificacion(item) {
      this.clasificacionSeleccionada = item
      this.modalEditarClasificacion = true
    },

    actulizarNuevaClasif(val) {
      val.Clasif_nombre = this.clasificaciones.find(clas => clas.Id === val.Clasif)?.Clasificacion
      this.clasificaciones.unshift(val)
    },
    actulizarEdicionClasif(val) {
      this.clasificacionesCompras.forEach(clas => {
        if (clas.Id === val.id) {
          clas.Clasif = val.clasificacion
          clas.Clasif_nombre = val.nombre_clasif
        }
      })
    },
  },
  computed: {
    ...mapState(['familias'])
  },

  async created() {
  },
  components: {
    BtnFiltro,
    EditarClasificacionVentas,
    NuevaClasificacionVentas,
    SearchDataTable
  },
};
</script>
