<template>
    <div>
        <v-row class="d-flex justify-center mt-0">
            <v-col cols="12">
                <v-expansion-panels v-model="panel">
                    <v-expansion-panel readonly>
                        <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
                            <div>
                                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                                Filtros
                            </div>
                            <template v-slot:actions>
                                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                                    <v-icon>fas fa-chevron-down</v-icon>
                                </v-btn>
                            </template>
                        </v-expansion-panel-header>
                        <!-- Filtros -->
                        <v-expansion-panel-content class="pt-1">
                            <v-form @submit.prevent="buscar">
                                <v-row :no-gutters="$vuetify.breakpoint.xs">
                                    <v-col cols="12" sm="3" md="2" class="py-1">
                                        Empresa
                                        <v-autocomplete v-model="filtro.familia_id" item-text="nombre" item-value="id"
                                            hide-details outlined dense :items="familias"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="12" class="pt-0" style="margin-top: -22px">
                                        <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-card class="mt-2">
                    <v-data-table class="cebra elevation-0 mt-2" item-key="id" sort-by="id" :headers="headers"
                        :items="stock" :loading="load" sort-desc dense :search="search">
                        <template v-slot:top>
                            <v-row class="d-flex pa-2 h-mobile" style="justify-content: space-between; height: 4.3rem"
                                no-gutters hide-details>
                                <v-col cols="12" sm="3" class="py-2">
                                    <div style="display: flex; align-items: center; gap: 10px">
                                        <SearchDataTable v-model="search" />
                                    </div>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:[`item.estado`]="{ item }">
                            <span v-if="!item.asignado">Disponible</span>
                            <span v-else>No disponible</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import BtnFiltro from '../components/util/BtnFiltro.vue';
import SearchDataTable from '../components/util/SearchDataTable.vue';
export default {
    data() {
        return {
            stock: [],
            load: false,
            panel: 0,
            search: '',
            headers: [
                { text: "Tipo", value: "Origen" },
                { text: "Estado", value: "estado" },
                { text: "Modelo descripción", value: "DescripcionOperativa" },
                { text: "Modelo código", value: "Modelo" },
                // { text: "Año", value: "Cod_autorizacion" },
                { text: "Color", value: "Descripcion" },
                { text: "Color interno", value: "ReservaCliente" },
                { text: "Ubicación", value: "Ubicacion" },
            ],
            filtro: {
                familia_id: null,
                tipo: '',
                estado: '',
                descripcion: '',
                codigo: '',
                anio: '',
                color: '',
                color_interno: '',
                odometro: '',
                ubicacion: ''
            },
            tipos: [
                '0km',
                'Usado'
            ]
        };
    },

    methods: {
        async buscar() {
            this.load = true
            if (!this.filtro.familia_id) {
                this.$store.dispatch('show_snackbar', {
                    text: 'Debes seleccionar una empresa.',
                    color: 'warning'
                })
                return
            }
            await this.$store.dispatch('stock/getStock', {
                familia_id: this.filtro.familia_id
            }).then(res => {
                if (res.exito) {
                    this.stock = res.data
                } else {
                    this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                    })
                }
            })
            this.load = false
        },


        limpiarFiltro() {
            this.filtro = {
                familia_id: null,
                tipo: '',
                estado: '',
                descripcion: '',
                codigo: '',
                anio: '',
                color: '',
                color_interno: '',
                odometro: '',
                ubicacion: ''
            }
        }

    },

    async created() {
        if (this.familias.length === 1) {
            this.filtro.familia_id = this.familias[0].id
        }
    },

    computed: {
        ...mapState(['familias'])
    },
    components: {
        BtnFiltro,
        SearchDataTable
    },
};
</script>
