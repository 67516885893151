<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn style="position: absolute; top: -15px; right: 55px; z-index: 1" color="success" small fab
              title="Descargar detalle" @click="descargarDetalle">
              <v-icon>fas fa-file-download</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Empresa
                    <v-autocomplete v-model="data.empresa" item-text="nombre" item-value="id" hide-details outlined
                      dense clearable :items="familias"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Tipo
                    <v-autocomplete v-model="data.tipo" item-text="Nombre" item-value="Id" hide-details outlined dense
                      :items="tipos_reportes" clearable></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Periódo
                    <PeriodoPicker v-model="data.periodo" hide-details clearable />
                  </v-col>
                  <v-col cols="12" sm="12" md="5" class="pt-3 mt-3 d-flex justify-end" style="">
                    <div style="display: flex; align-items: center; gap: 1rem;">
                      <div style="display: flex; margin-bottom: 1.1rem;">
                        <v-checkbox label="F 2002" v-model="formato_2002" hide-details></v-checkbox>
                      </div>
                      <v-btn color="success" :loading="load" @click="generar">
                        <v-icon class="" style="padding-right: 4px;">fas fa-directions</v-icon>
                        Generar reporte
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card class="mt-2">
          <v-data-table class="cebra elevation-0 mt-2" item-key="id" sort-by="id" :headers="headers" :items="reporte"
            :item-class="getRowClass" :items-per-page="-1" :loading="load" sort-desc dense @click:row="onRowClick">
            <template v-for="header in headers.filter(header =>
              header.hasOwnProperty('formatter')
            )" v-slot:[`item.${header.value}`]="{ value }">
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`item.SumaExento`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaExento) }}
              </span>
              <span v-else>
                {{ item.SumaExento }}
              </span>
            </template>
            <template v-slot:[`item.SumaGravado`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaGravado) }}
              </span>
              <span v-else>
                {{ item.SumaGravado }}
              </span>
            </template>
            <template v-slot:[`item.SumaIVA21`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaIVA21) }}
              </span>
              <span v-else>
                {{ item.SumaIVA21 }}
              </span>
            </template>
            <template v-slot:[`item.SumaIVA105`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaIVA105) }}
              </span>
              <span v-else>
                {{ item.SumaIVA105 }}
              </span>
            </template>
            <template v-slot:[`item.SumaIVA27`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaIVA27) }}
              </span>
              <span v-else>
                {{ item.SumaIVA27 }}
              </span>
            </template>
            <template v-slot:[`item.SumaPercepIVA`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaPercepIVA) }}
              </span>
              <span v-else>
                {{ item.SumaPercepIVA }}
              </span>
            </template>
            <template v-slot:[`item.SumaImpInt`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaImpInt) }}
              </span>
              <span v-else>
                {{ item.SumaImpInt }}
              </span>
            </template>
            <template v-slot:[`item.SumaTotal`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaTotal) }}
              </span>
              <span v-else>
                {{ item.SumaTotal }}
              </span>
            </template>
            <template v-slot:[`item.SumaGravado21`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaGravado21) }}
              </span>
              <span v-else>
                {{ item.SumaGravado21 }}
              </span>
            </template>
            <template v-slot:[`item.SumaGravado105`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaGravado105) }}
              </span>
              <span v-else>
                {{ item.SumaGravado105 }}
              </span>
            </template>
            <template v-slot:[`item.SumaGravado27`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaGravado27) }}
              </span>
              <span v-else>
                {{ item.SumaGravado27 }}
              </span>
            </template>
            <template v-slot:[`item.TotalIVA21`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.TotalIVA21) }}
              </span>
              <span v-else>
                {{ item.TotalIVA21 }}
              </span>
            </template>
            <template v-slot:[`item.TotalIVA105`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.TotalIVA105) }}
              </span>
              <span v-else>
                {{ item.TotalIVA105 }}
              </span>
            </template>
            <template v-slot:[`item.TotalIVA27`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.TotalIVA27) }}
              </span>
              <span v-else>
                {{ item.TotalIVA27 }}
              </span>
            </template>
            <template v-slot:[`item.SumaExento`]="{ item }">
              <span v-if="!formato_2002">
                {{ format_money(item.SumaExento) }}
              </span>
              <span v-else>
                {{ item.SumaExento }}
              </span>
            </template>

            <!-- <template v-slot:footer> -->
            <!-- <v-row class="d-flex h-mobile" style="justify-content: end" no-gutters hide-details>
                <v-col cols="12" sm="2" class="py-4 px-1">
                  <v-text-field-money label="Total exento" v-bind:properties="{
                    prefix: '$',
                    type: 'tel',
                    outlined: true,
                    dense: true,
                    hideDetails: true,
                    readonly: true
                  }" v-bind:options="{
                    locale: 'es-AR',
                    precision: 2,
                    empty: null,
                  }" v-model="totales.exento" />
                </v-col>
              </v-row>
            </template> -->

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import BtnConfirmar from '../../../components/util/BtnConfirmar.vue';
import PeriodoPicker from '../../../components/util/PeriodoPicker.vue';
import { utils, write } from 'xlsx';
import FileSaver from 'file-saver';
import { format_date } from '../../../util/utils';

export default {
  data() {
    return {
      panel: 0,
      load: false,
      data: {
        empresa: null,
        tipo: null,
        periodo: moment().subtract(1, 'months').format('MM/YYYY'),
      },
      formato_2002: true,
      headers: [],
      tipos_reportes: [],
      reporte: [],
      detalle: [],
      totales: {
        exento: 0,
        gravado_21: 0,
        suma_21: 0,
        gravado_105: 0,
        suma_105: 0,
        gravado_27: 0,
        suma_27: 0,
      },
      selectedRow: null,
    };
  },
  methods: {
    async generar() {
      if (!this.data.empresa || !this.data.tipo || !this.data.periodo) {
        this.$store.dispatch('show_snackbar', {
          text: 'Todos los campos son obligatorios.',
          color: 'warning'
        })
        return
      }
      const periodo = `01/${this.data.periodo}`
      this.load = true
      await this.$store.dispatch('reportes/getReporte', {
        periodo,
        empresa: this.data.empresa,
        tipo: this.data.tipo
      }).then(res => {
        if (res.exito) {
          this.reporte = res.data
          this.reporte = this.reporte.sort((a, b) =>
            a.Clasificacion.localeCompare(b.Clasificacion)
          )
          this.reporte.forEach(item => {
            const suma21 = item.SumaIVA21 || 0
            const gravado21 = item.SumaGravado21 || 0
            item.TotalIVA21 = Number((suma21 + gravado21).toFixed(2));
            const suma105 = item.SumaIVA105 || 0
            const gravado105 = item.SumaGravado105 || 0
            item.TotalIVA105 = Number((suma105 + gravado105).toFixed(2))
            const suma27 = item.SumaIVA27 || 0
            const gravado27 = item.SumaGravado27 || 0
            item.TotalIVA27 = Number((suma27 + gravado27).toFixed(2))
          })

          const rowTotales = this.calcularTotales(this.reporte)

          this.reporte = [...this.reporte, rowTotales]

        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      }).catch(err => {
        this.$store.dispatch('show_snackbar', {
          text: err.message,
          color: 'error'
        })
        this.load = false
      })
      this.load = false
    },


    async getReporteDescargar(tipo) {
      let reporte = []
      if (!this.data.empresa || !tipo || !this.data.periodo) {
        this.$store.dispatch('show_snackbar', {
          text: 'Todos los campos son obligatorios.',
          color: 'warning'
        })
        return
      }
      const periodo = `01/${this.data.periodo}`
      this.load = true
      await this.$store.dispatch('reportes/getReporte', {
        periodo,
        empresa: this.data.empresa,
        tipo: tipo
      }).then(res => {
        if (res.exito) {
          reporte = res.data
          reporte = reporte.sort((a, b) =>
            a.Clasificacion.localeCompare(b.Clasificacion)
          )
          reporte.forEach(item => {
            const suma21 = item.SumaIVA21 || 0
            const gravado21 = item.SumaGravado21 || 0
            item.TotalIVA21 = Number((suma21 + gravado21).toFixed(2));
            const suma105 = item.SumaIVA105 || 0
            const gravado105 = item.SumaGravado105 || 0
            item.TotalIVA105 = Number((suma105 + gravado105).toFixed(2))
            const suma27 = item.SumaIVA27 || 0
            const gravado27 = item.SumaGravado27 || 0
            item.TotalIVA27 = Number((suma27 + gravado27).toFixed(2))
          })

          const rowTotales = this.calcularTotales(reporte)

          reporte = [...reporte, rowTotales]

        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      }).catch(err => {
        this.$store.dispatch('show_snackbar', {
          text: err.message,
          color: 'error'
        })
        this.load = false
      })
      this.load = false
      return reporte
    },

    calcularTotales(reporte) {
      function calcularTotal(campo) {
        return reporte.reduce((acc, item) => acc + (item[campo] || 0), 0).toFixed(2);
      }

      const totalExento = Number(calcularTotal.call(this, 'SumaExento'));
      const totalGravado21 = Number(calcularTotal.call(this, 'SumaGravado21'));
      const totalSuma21 = Number(calcularTotal.call(this, 'SumaIVA21'));
      const total21 = Number(calcularTotal.call(this, 'TotalIVA21'));

      const totalGravado105 = Number(calcularTotal.call(this, 'SumaGravado105'));
      const totalSuma105 = Number(calcularTotal.call(this, 'SumaIVA105'));
      const total105 = Number(calcularTotal.call(this, 'TotalIVA105'));

      const totalSuma27 = Number(calcularTotal.call(this, 'SumaIVA27'));
      const totalGravado27 = Number(calcularTotal.call(this, 'SumaGravado27'));
      const total27 = Number(calcularTotal.call(this, 'TotalIVA27'));

      return {
        Clasificacion: 'Totales',
        SumaExento: totalExento,
        SumaGravado21: totalGravado21,
        SumaIVA21: totalSuma21,
        TotalIVA21: total21,
        SumaGravado105: totalGravado105,
        SumaIVA105: totalSuma105,
        TotalIVA105: total105,
        SumaIVA27: totalSuma27,
        SumaGravado27: totalGravado27,
        TotalIVA27: total27,
        color: 'info'
      };
    },

    format_money(money) {
      if (!money && money != 0) return ''
      return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(money)
    },

    async descargarDetalle() {
      if (!this.data.empresa || !this.data.tipo || !this.data.periodo || this.reporte.length == 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'Genera el reporte antes de descargar el detalle.',
          color: 'warning'
        })
        return
      }
      this.$store.state.loading = true
      await this.$store.dispatch('reportes/getDetalle', {
        ...this.data
      }).then(async res => {
        if (res.exito) {
          this.detalle = res.data
          await this.descargar(this.detalle)
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })
      this.$store.state.loading = false
    },

    async descargar(detalle) {

      if (detalle.length > 0) {
        let cabecera = []
        let tituloArchivo = ''
        let header = []
        let array = []
        switch (this.data.tipo) {
          case 1:
            tituloArchivo = 'Detalle_ventas_'
            cabecera = [[
              'Cuenta IIBB',
              'Clasificación IIBB',
              'Clasificación IVA',
              'Comprobante',
              'Fecha',
              'FechaContable',
              'Cliente',
              'Tipo de IVA',
              'CUIT/DNI',
              'Exento',
              'Gravado',
              'IVA 21%',
              'IVA 10,5%',
              'IVA 27%',
              'Otras Tasas',
              'TOTAL IVA',
              'Perc. IVA',
              'Perc. IVANoCat.',
              'Perc. IIBB',
              'I. Internos',
              'Sellado',
              'Perc. SeH',
              'Perc. Mun.',
              'Total',
              'Diferencia',
              'Asiento',
              'Localidad',
              'Provincia',
              'Cuentas'
            ]]
            detalle.forEach((el) => {
              let data = {
                cuentaIIBB: el.CuentaClasifIIBB,
                clasifIIBB: el.ClasifIIBB,
                clasificacionIVA: el.Clasificacion,
                comprobante: el.Comprobante,
                fecha: format_date(el.Fecha),
                fechaContable: format_date(el.FechaContable),
                cliente: el.Nombre,
                tipoDeIVA: el.Condicion,
                cuitdni: el.CUITCUILDNI,
                exento: el.Exento,
                gravado: el.Gravado,
                iva_21: el.SumaDeIVA21,
                iva_105: el.SumaDeIVA10,
                iva27: el.SumaDeIVA27,
                otrasTasas: el.SumaDeTasaVariable,
                totalIVA: el.IVA,
                percIVA: el.PercepcionIVA,
                percIVANoCat: el.PercepcionIVANoCateg,
                percIIBB: el.PercepcionIB,
                iinternos: el.ImpInt,
                sellado: el.ImpSellado,
                perc_seh: el.PercepSeh,
                perc_mun: el.PercepMunicipal,
                total: el.Total,
                diferencia: el.Diferencia,
                asiento: el.ComprobantesAsiento,
                localidad: el.Localidad,
                provincia: el.Provincia,
                Cuentas: el.CUENTAS
              }
              array.push(data);
            });

            header = Object.keys(array[0])

            break;
          case 2:
            tituloArchivo = 'Detalle_ventas_'
            cabecera = [[
              'Cuenta IIBB',
              'Clasificación IIBB',
              'Clasificación IVA',
              'Comprobante',
              'Fecha',
              'FechaContable',
              'Cliente',
              'Tipo de IVA',
              'CUIT/DNI',
              'Exento',
              'Gravado',
              'IVA 21%',
              'IVA 10,5%',
              'IVA 27%',
              'Otras Tasas',
              'TOTAL IVA',
              'Perc. IVA',
              'Perc. IVANoCat.',
              'Perc. IIBB',
              'I. Internos',
              'Sellado',
              'Perc. SeH',
              'Perc. Mun.',
              'Total',
              'Diferencia',
              'Asiento',
              'Localidad',
              'Provincia',
              'Cuentas'
            ]]
            detalle.forEach((el) => {
              let data = {
                cuentaIIBB: el.CuentaClasifIIBB,
                clasifIIBB: el.ClasifIIBB,
                clasificacionIVA: el.Clasificacion,
                comprobante: el.Comprobante,
                fecha: format_date(el.Fecha),
                fechaContable: format_date(el.FechaContable),
                cliente: el.Nombre,
                tipoDeIVA: el.Condicion,
                cuitdni: el.CUITCUILDNI,
                exento: el.Exento,
                gravado: el.Gravado,
                iva_21: el.SumaDeIVA21,
                iva_105: el.SumaDeIVA10,
                iva27: el.SumaDeIVA27,
                otrasTasas: el.SumaDeTasaVariable,
                totalIVA: el.IVA,
                percIVA: el.PercepcionIVA,
                percIVANoCat: el.PercepcionIVANoCateg,
                percIIBB: el.PercepcionIB,
                iinternos: el.ImpInt,
                sellado: el.ImpSellado,
                perc_seh: el.PercepSeh,
                perc_mun: el.PercepMunicipal,
                total: el.Total,
                diferencia: el.Diferencia,
                asiento: el.ComprobantesAsiento,
                localidad: el.Localidad,
                provincia: el.Provincia,
                Cuentas: el.CUENTAS
              }
              array.push(data);
            });
            header = Object.keys(array[0])
            break;
          case 3:
            tituloArchivo = 'Detalle_compras_'
            cabecera = [[
              'Cuenta Clasif',
              'Clasificación',
              'Comprobante',
              'Fecha',
              'Fecha Contable',
              'Cliente',
              'Tipo de IVA',
              'CUIT/DNI',
              'Exento',
              'Gravado',
              'IVA 21%',
              'IVA 10,5%',
              'IVA 27%',
              'Otras Tasas',
              'Total IVA',
              'Per. IVA',
              'Per. I.B.',
              'Per. I.B. Pcia.',
              'Perc. II.BB.',
              'I. Internos',
              'I. Ganancia',
              'Per. SeH',
              'Total',
              'CAI/CAE',
              'Diferencia',
              'Asiento Nro',
              'Localidad',
              'Provincia',
              'Cuentas'
            ]]
            detalle.forEach((el) => {
              let data = {
                cuentaCalsif: el.CuentaClasif,
                clasificacion: el.Clasificacion,
                comprobante: el.Comprobante,
                fecha: format_date(el.Fecha),
                fechaContable: format_date(el.FechaContable),
                cliente: el.Nombre,
                tipoDeIVA: el.Condicion,
                cuitdni: el.CUITCUILDNI,
                exento: el.Exento,
                gravado: el.Gravado,
                iva_21: el.SumaDeIVA21,
                iva_105: el.SumaDeIVA10,
                iva27: el.SumaDeIVA27,
                otrasTasas: el.SumaDeTasaVariable,
                totalIVA: el.IVA,
                percIVA: el.PercepcionIVA,
                percIB: el.PercepcionIB,
                percIIBBPvcia: el.PercepcionIBPcia,
                percIIBB: el.PercIIBB,
                iinternos: el.ImpInt,
                iGanancias: el.AnticipoGanancia,
                perSeH: el.PercepcionSeH,
                total: el.Total,
                caicae: el.CAI_CAE,
                diferencia: el.Diferencia,
                asiento: el.COAsiento,
                localidad: el.Localidad,
                provincia: el.Provincia,
                Cuentas: el.CUENTAS
              }
              array.push(data);
            });
            header = Object.keys(array[0])
            break;
          case 4:
            tituloArchivo = 'Detalle_compras_'
            cabecera = [[
              'Cuenta Clasif',
              'Clasificación',
              'Comprobante',
              'Fecha',
              'Fecha Contable',
              'Cliente',
              'Tipo de IVA',
              'CUIT/DNI',
              'Exento',
              'Gravado',
              'IVA 21%',
              'IVA 10,5%',
              'IVA 27%',
              'Otras Tasas',
              'Total IVA',
              'Per. IVA',
              'Per. I.B.',
              'Per. I.B. Pcia.',
              'Perc. II.BB.',
              'I. Internos',
              'I. Ganancia',
              'Per. SeH',
              'Total',
              'CAI/CAE',
              'Diferencia',
              'Asiento Nro',
              'Localidad',
              'Provincia',
              'Cuentas'
            ]]
            detalle.forEach((el) => {
              let data = {
                cuentaCalsif: el.CuentaClasif,
                clasificacion: el.Clasificacion,
                comprobante: el.Comprobante,
                fecha: format_date(el.Fecha),
                fechaContable: format_date(el.FechaContable),
                cliente: el.Nombre,
                tipoDeIVA: el.Condicion,
                cuitdni: el.CUITCUILDNI,
                exento: el.Exento,
                gravado: el.Gravado,
                iva_21: el.SumaDeIVA21,
                iva_105: el.SumaDeIVA10,
                iva27: el.SumaDeIVA27,
                otrasTasas: el.SumaDeTasaVariable,
                totalIVA: el.IVA,
                percIVA: el.PercepcionIVA,
                percIB: el.PercepcionIB,
                percIIBBPvcia: el.PercepcionIBPcia,
                percIIBB: el.PercIIBB,
                iinternos: el.ImpInt,
                iGanancias: el.AnticipoGanancia,
                perSeH: el.PercepcionSeH,
                total: el.Total,
                caicae: el.CAI_CAE,
                diferencia: el.Diferencia,
                asiento: el.COAsiento,
                localidad: el.Localidad,
                provincia: el.Provincia,
                Cuentas: el.CUENTAS
              }
              array.push(data);
            });
            header = Object.keys(array[0])
            break;
          default:
            break;
        }
        const ws = utils.aoa_to_sheet(cabecera);
        utils.sheet_add_json(ws, array, {
          header,
          skipHeader: true,
          origin: -1,
        });



        const columnas = Object.keys(array[0]);
        columnas.forEach((columna) => {
          const maxLength = array.reduce(
            (max, row) => Math.max(max, String(row[columna]).length),
            0
          );
          ws["!cols"] = ws["!cols"] || [];
          ws["!cols"].push({ wch: maxLength + 2 });
        });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Detalle");

        const { nombreHoja2, nombreHoja3 } = this.nombresHojas()
        const cabeceraSegundaTerceraHoja = this.cabecerasExcel()


        const { data2, data3 } = await this.recuperarReportes()

        // HOJA 2
        const ws2 = utils.aoa_to_sheet(cabeceraSegundaTerceraHoja)
        utils.sheet_add_json(ws2, data2, { skipHeader: true, origin: -1 })
        utils.book_append_sheet(wb, ws2, nombreHoja2)

        const columnas2 = Object.keys(data2[0]);
        columnas2.forEach((columna) => {
          const maxLength = data2.reduce(
            (max, row) => Math.max(max, String(row[columna]).length),
            0
          );
          ws2["!cols"] = ws2["!cols"] || [];
          ws2["!cols"].push({ wch: maxLength + 2 });
        });

        // HOJA 3
        const ws3 = utils.aoa_to_sheet(cabeceraSegundaTerceraHoja)
        utils.sheet_add_json(ws3, data3, { skipHeader: true, origin: -1 })
        utils.book_append_sheet(wb, ws3, nombreHoja3)

        const columnas3 = Object.keys(data3[0]);
        columnas3.forEach((columna) => {
          const maxLength = data3.reduce(
            (max, row) => Math.max(max, String(row[columna]).length),
            0
          );
          ws3["!cols"] = ws3["!cols"] || [];
          ws3["!cols"].push({ wch: maxLength + 2 });
        });

        const wbout = write(wb, { bookType: "xlsx", type: "array" });
        let name = `${tituloArchivo}` + moment().format("DD/MM/YYYY") + ".xlsx";
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          name
        );
      } else {
        return
      }
    },

    cabecerasExcel() {
      let cabeceras = []
      switch (this.data.tipo) {
        case 1:
          cabeceras = [[
            'Clasificación',
            'Tipo IVA',
            'Suma Exento',
            'Gravado IVA 21%',
            'Suma IVA 21%',
            'Total IVA 21%',
            'Gravado IVA 10,5%',
            'Suma IVA 10,5%',
            'Total IVA 10,5%',
            'Gravado IVA 27%',
            'Suma IVA 27%',
            'Total IVA 27%',
          ]]
          break;
        case 2:
          cabeceras = [[
            'Clasificación',
            'Tipo IVA',
            'Suma Exento',
            'Gravado IVA 21%',
            'Suma IVA 21%',
            'Total IVA 21%',
            'Gravado IVA 10,5%',
            'Suma IVA 10,5%',
            'Total IVA 10,5%',
            'Gravado IVA 27%',
            'Suma IVA 27%',
            'Total IVA 27%',
          ]]
          break
        case 3:
          cabeceras = [[
            'Clasificación',
            'Suma Exento',
            'Gravado IVA 21%',
            'Suma IVA 21%',
            'Total IVA 21%',
            'Gravado IVA 10,5%',
            'Suma IVA 10,5%',
            'Total IVA 10,5%',
            'Gravado IVA 27%',
            'Suma IVA 27%',
            'Total IVA 27%',
          ]]
          break
        case 4:
          cabeceras = [[
            'Clasificación',
            'Suma Exento',
            'Gravado IVA 21%',
            'Suma IVA 21%',
            'Total IVA 21%',
            'Gravado IVA 10,5%',
            'Suma IVA 10,5%',
            'Total IVA 10,5%',
            'Gravado IVA 27%',
            'Suma IVA 27%',
            'Total IVA 27%',
          ]]
          break
        default:
          break;
      }
      return cabeceras

    },

    nombresHojas() {
      let nombreHoja2 = ''
      let nombreHoja3 = ''
      switch (this.data.tipo) {
        case 1:
          nombreHoja2 = 'Débito Fiscal'
          nombreHoja3 = 'Débito Fiscal a restituir'
          break;
        case 2:
          nombreHoja2 = 'Débito Fiscal'
          nombreHoja3 = 'Débito Fiscal a restituir'
          break;
        case 3:
          nombreHoja2 = 'Crédito Fiscal'
          nombreHoja3 = 'Crédito Fiscal a restituir'
          break;
        case 4:
          nombreHoja2 = 'Crédito Fiscal'
          nombreHoja3 = 'Crédito Fiscal a restituir'
          break;
        default:
          break;
      }
      return {
        nombreHoja2, nombreHoja3
      }
    },

    async recuperarReportes() {
      let data2Provisoria = []
      let data3Provisoria = []

      let data2 = []
      let data3 = []

      switch (this.data.tipo) {
        case 1:
          data2Provisoria = this.reporte
          data3Provisoria = await this.getReporteDescargar(2)

          data2 = data2Provisoria.map((data) => {
            const {
              Clasificacion: clasificacion,
              TipoIVA: tipoIVA,
              SumaExento: sumaExento,
              SumaGravado21: gravadoIVA21,
              SumaIVA21: sumaIVA21,
              TotalIVA21: totalIVA21,
              SumaGravado105: gravadoIVA105,
              SumaIVA105: sumaIVA105,
              TotalIVA105: totalIVA105,
              SumaGravado27: gravadoIVA27,
              SumaIVA27: sumaIVA27,
              TotalIVA27: totalIVA27
            } = data

            return {
              clasificacion,
              tipoIVA,
              sumaExento,
              gravadoIVA21,
              sumaIVA21,
              totalIVA21,
              gravadoIVA105,
              sumaIVA105,
              totalIVA105,
              gravadoIVA27,
              sumaIVA27,
              totalIVA27
            }
          })

          data3 = data3Provisoria.map((data) => {
            const {
              Clasificacion: clasificacion,
              TipoIVA: tipoIVA,
              SumaExento: sumaExento,
              SumaGravado21: gravadoIVA21,
              SumaIVA21: sumaIVA21,
              TotalIVA21: totalIVA21,
              SumaGravado105: gravadoIVA105,
              SumaIVA105: sumaIVA105,
              TotalIVA105: totalIVA105,
              SumaGravado27: gravadoIVA27,
              SumaIVA27: sumaIVA27,
              TotalIVA27: totalIVA27
            } = data

            return {
              clasificacion,
              tipoIVA,
              sumaExento,
              gravadoIVA21,
              sumaIVA21,
              totalIVA21,
              gravadoIVA105,
              sumaIVA105,
              totalIVA105,
              gravadoIVA27,
              sumaIVA27,
              totalIVA27
            }
          })
          break;
        case 2:
          data2Provisoria = await this.getReporteDescargar(1)
          data3Provisoria = this.reporte

          data3 = data3Provisoria.map((data) => {
            const {
              Clasificacion: clasificacion,
              TipoIVA: tipoIVA,
              SumaExento: sumaExento,
              SumaGravado21: gravadoIVA21,
              SumaIVA21: sumaIVA21,
              TotalIVA21: totalIVA21,
              SumaGravado105: gravadoIVA105,
              SumaIVA105: sumaIVA105,
              TotalIVA105: totalIVA105,
              SumaGravado27: gravadoIVA27,
              SumaIVA27: sumaIVA27,
              TotalIVA27: totalIVA27
            } = data

            return {
              clasificacion,
              tipoIVA,
              sumaExento,
              gravadoIVA21,
              sumaIVA21,
              totalIVA21,
              gravadoIVA105,
              sumaIVA105,
              totalIVA105,
              gravadoIVA27,
              sumaIVA27,
              totalIVA27
            }
          })

          data2 = data2Provisoria.map((data) => {
            const {
              Clasificacion: clasificacion,
              TipoIVA: tipoIVA,
              SumaExento: sumaExento,
              SumaGravado21: gravadoIVA21,
              SumaIVA21: sumaIVA21,
              TotalIVA21: totalIVA21,
              SumaGravado105: gravadoIVA105,
              SumaIVA105: sumaIVA105,
              TotalIVA105: totalIVA105,
              SumaGravado27: gravadoIVA27,
              SumaIVA27: sumaIVA27,
              TotalIVA27: totalIVA27
            } = data

            return {
              clasificacion,
              tipoIVA,
              sumaExento,
              gravadoIVA21,
              sumaIVA21,
              totalIVA21,
              gravadoIVA105,
              sumaIVA105,
              totalIVA105,
              gravadoIVA27,
              sumaIVA27,
              totalIVA27
            }
          })

          break;
        case 3:
          data2Provisoria = this.reporte
          data3Provisoria = await this.getReporteDescargar(4)

          data2 = data2Provisoria.map((data) => {
            const {
              Clasificacion: clasificacion,
              SumaExento: sumaExento,
              SumaGravado21: gravadoIVA21,
              SumaIVA21: sumaIVA21,
              TotalIVA21: totalIVA21,
              SumaGravado105: gravadoIVA105,
              SumaIVA105: sumaIVA105,
              TotalIVA105: totalIVA105,
              SumaGravado27: gravadoIVA27,
              SumaIVA27: sumaIVA27,
              TotalIVA27: totalIVA27
            } = data

            return {
              clasificacion,
              sumaExento,
              gravadoIVA21,
              sumaIVA21,
              totalIVA21,
              gravadoIVA105,
              sumaIVA105,
              totalIVA105,
              gravadoIVA27,
              sumaIVA27,
              totalIVA27
            }
          })

          data3 = data3Provisoria.map((data) => {
            const {
              Clasificacion: clasificacion,
              SumaExento: sumaExento,
              SumaGravado21: gravadoIVA21,
              SumaIVA21: sumaIVA21,
              TotalIVA21: totalIVA21,
              SumaGravado105: gravadoIVA105,
              SumaIVA105: sumaIVA105,
              TotalIVA105: totalIVA105,
              SumaGravado27: gravadoIVA27,
              SumaIVA27: sumaIVA27,
              TotalIVA27: totalIVA27
            } = data

            return {
              clasificacion,
              sumaExento,
              gravadoIVA21,
              sumaIVA21,
              totalIVA21,
              gravadoIVA105,
              sumaIVA105,
              totalIVA105,
              gravadoIVA27,
              sumaIVA27,
              totalIVA27
            }
          })

          break;
        case 4:
          data2Provisoria = await this.getReporteDescargar(3)
          data3Provisoria = this.reporte

          data3 = this.reporte.map((data) => {
            const {
              Clasificacion: clasificacion,
              SumaExento: sumaExento,
              SumaGravado21: gravadoIVA21,
              SumaIVA21: sumaIVA21,
              TotalIVA21: totalIVA21,
              SumaGravado105: gravadoIVA105,
              SumaIVA105: sumaIVA105,
              TotalIVA105: totalIVA105,
              SumaGravado27: gravadoIVA27,
              SumaIVA27: sumaIVA27,
              TotalIVA27: totalIVA27
            } = data

            return {
              clasificacion,
              sumaExento,
              gravadoIVA21,
              sumaIVA21,
              totalIVA21,
              gravadoIVA105,
              sumaIVA105,
              totalIVA105,
              gravadoIVA27,
              sumaIVA27,
              totalIVA27
            }
          })

          data2 = data2Provisoria.map((data) => {
            const {
              Clasificacion: clasificacion,
              SumaExento: sumaExento,
              SumaGravado21: gravadoIVA21,
              SumaIVA21: sumaIVA21,
              TotalIVA21: totalIVA21,
              SumaGravado105: gravadoIVA105,
              SumaIVA105: sumaIVA105,
              TotalIVA105: totalIVA105,
              SumaGravado27: gravadoIVA27,
              SumaIVA27: sumaIVA27,
              TotalIVA27: totalIVA27
            } = data

            return {
              clasificacion,
              sumaExento,
              gravadoIVA21,
              sumaIVA21,
              totalIVA21,
              gravadoIVA105,
              sumaIVA105,
              totalIVA105,
              gravadoIVA27,
              sumaIVA27,
              totalIVA27
            }
          })
          break
        default:
          break;
      }
      return {
        data2, data3
      }
    },
    getRowClass(item) {
      if (this.selectedRow === item) {
        return "selected-row";
      }
      return item.color === "info" ? "info" : "";
    },
    onRowClick(item) {
      this.selectedRow = item;
    },
  },
  computed: {
    ...mapState(['familias'])
  },
  watch: {
    'data.tipo'(val) {
      this.reporte = []
      switch (val) {
        case 1:
          this.headers = [
            { text: 'Clasificación', value: 'Clasificacion', width: "220px" },
            { text: 'Tipo IVA', value: 'TipoIVA' },
            { text: 'Suma Exento', value: 'SumaExento' },
            { text: 'Gravado IVA 21%', value: 'SumaGravado21', align: 'end' },
            { text: 'Suma IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Total IVA 21%', value: 'TotalIVA21', align: 'end' },
            { text: 'Gravado IVA 10,5%', value: 'SumaGravado105', align: 'end' },
            { text: 'Suma IVA 10,5%', value: 'SumaIVA105', align: 'end' },
            { text: 'Total IVA 10,5%', value: 'TotalIVA105', align: 'end' },
            { text: 'Gravado IVA 27%', value: 'SumaGravado27', align: 'end' },
            { text: 'Suma IVA 27%', value: 'SumaIVA27', align: 'end' },
            { text: 'Total IVA 27%', value: 'TotalIVA27', align: 'end' },
          ]
          break;
        case 2:
          this.headers = [
            { text: 'Clasificación', value: 'Clasificacion', width: "220px" },
            { text: 'Tipo IVA', value: 'TipoIVA' },
            { text: 'Suma Exento', value: 'SumaExento' },
            { text: 'Gravado IVA 21%', value: 'SumaGravado21', align: 'end' },
            { text: 'Suma IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Total IVA 21%', value: 'TotalIVA21', align: 'end' },
            { text: 'Gravado IVA 10,5%', value: 'SumaGravado105', align: 'end' },
            { text: 'Suma IVA 10,5%', value: 'SumaIVA105', align: 'end' },
            { text: 'Total IVA 10,5%', value: 'TotalIVA105', align: 'end' },
            { text: 'Gravado IVA 27%', value: 'SumaGravado27', align: 'end' },
            { text: 'Suma IVA 27%', value: 'SumaIVA27', align: 'end' },
            { text: 'Total IVA 27%', value: 'TotalIVA27', align: 'end' },
          ]
          break;
        case 3:
          this.headers = [
            { text: 'Clasificación', value: 'Clasificacion', width: "220px" },
            { text: 'Suma Exento', value: 'SumaExento' },
            { text: 'Gravado IVA 21%', value: 'SumaGravado21', align: 'end' },
            { text: 'Suma IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Total IVA 21%', value: 'TotalIVA21', align: 'end' },
            { text: 'Gravado IVA 10,5%', value: 'SumaGravado105', align: 'end' },
            { text: 'Suma IVA 10,5%', value: 'SumaIVA105', align: 'end' },
            { text: 'Total IVA 10,5%', value: 'TotalIVA105', align: 'end' },
            { text: 'Gravado IVA 27%', value: 'SumaGravado27', align: 'end' },
            { text: 'Suma IVA 27%', value: 'SumaIVA27', align: 'end' },
            { text: 'Total IVA 27%', value: 'TotalIVA27', align: 'end' },
          ]
          break;
        case 4:
          this.headers = [
            { text: 'Clasificación', value: 'Clasificacion', width: "220px" },
            { text: 'Suma Exento', value: 'SumaExento' },
            { text: 'Gravado IVA 21%', value: 'SumaGravado21', align: 'end' },
            { text: 'Suma IVA 21%', value: 'SumaIVA21', align: 'end' },
            { text: 'Total IVA 21%', value: 'TotalIVA21', align: 'end' },
            { text: 'Gravado IVA 10,5%', value: 'SumaGravado105', align: 'end' },
            { text: 'Suma IVA 10,5%', value: 'SumaIVA105', align: 'end' },
            { text: 'Total IVA 10,5%', value: 'TotalIVA105', align: 'end' },
            { text: 'Gravado IVA 27%', value: 'SumaGravado27', align: 'end' },
            { text: 'Suma IVA 27%', value: 'SumaIVA27', align: 'end' },
            { text: 'Total IVA 27%', value: 'TotalIVA27', align: 'end' },
          ]
          break;
        default:
          break;
      }
    },
    'data.empresa'(val) {
      this.reporte = []
      this.detalle = []
    },
  },
  async created() {
    await this.$store.dispatch('reportes/getTiposReportes').then(res => {
      if (res.exito) {
        this.tipos_reportes = res.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error'
        })
      }
    })
  },
  components: {
    PeriodoPicker,
    BtnConfirmar
  },
};
</script>

<style>
.selected-row {
  background-color: #d9edf7 !important;
  /* Color azul claro para la selección */
  color: #31708f !important;
  /* Texto oscuro */
}
</style>